/**
 * @ngdoc config
 * @name routeProviderConfig
 * @description
 * Sets the routes.
 *
 * @param {$routeProvider} $routeProvider  The interface for the Angular router.
 * @param {Object}         NAVIGATION      The interface for the Angular router.
 *
 * @memberof contentEngineAdmin
 */
const routeProviderConfig = ($routeProvider, NAVIGATION) => {
  'ngInject';

  // Routes without sub navigation.
  [
    // Home route
    NAVIGATION.home,

    // Content route
    NAVIGATION.homeContent,
  ]
  .forEach((item) => {
    if (item.route) {
      $routeProvider.when(item.route, item.config || {});
    }
  });

  // Routes with sub navigation.
  [
    // Avatar routes
    NAVIGATION.avatarItems,

    // MainNav routes
    NAVIGATION.mainNav,

    // SubNav routes
    NAVIGATION.subNav,

    // Unmapped routes
    NAVIGATION.unmapped,

    // Legacy routes
    NAVIGATION.legacy,
  ]

  .forEach((items) => items.forEach((item) => {
    if (item.route) {
      const routeConfig = { ...item.config, pageTitle: item.title };
      $routeProvider.when(item.route, routeConfig);
    }

    if (angular.isArray(item.items)) {
      item.items.forEach((subItem) => {
        if (subItem.route) {
          const subRouteConfig = { ...subItem.config, pageTitle: subItem.title };
          $routeProvider.when(subItem.route, subRouteConfig);
        }

        if (subItem.items) {
          subItem.items.forEach((secondIt) => {
            const secondRouteConfig = { ...secondIt.config, pageTitle: secondIt.title };
            $routeProvider.when(secondIt.route, secondRouteConfig);

            if (secondIt.items) {
              secondIt.items.forEach((thirdIt) => {
                const thirdRouteConfig = { ...thirdIt.config, pageTitle: thirdIt.title };
                $routeProvider.when(thirdIt.route, thirdRouteConfig);
              });
            }
          });
        }
      });
    }
  }));

  $routeProvider
  .when(
    '/',
    { redirectTo: NAVIGATION.homeContent.route },
  )
  .otherwise({
    redirectTo: NAVIGATION.homeContent.route,
  });
};

export default routeProviderConfig;
