import angular from 'angular';

import requestApprovalsContainer from './containers/requestapprovals.container';

const SETTINGS_MESSAGES = {
  facebookConfirmation: `You are about to start the process to connect a Facebook account to Olapic.
        This will allow you to: <br>
        - Collect by user mention and hashtags.<br>
        - Request rights on content.<br>
        - Post via Content Scheduler.<br><br>
        Make sure:<br>
        - The account you are connecting has Admin or Editor role to the Facebook page.<br>
        - The instagram Account you want to collect from is set to business and is associated to the Facebook page.`,
  facebookSuccess: `Well done! You have successfully connected your Facebook account.<br><br>
        You can now:<br>
        - Collect by user mention and hashtag (a collection needs to be created).<br>
        - Request Rights on content.`,
};

/**
 * @ngdoc overview
 * @name settings
 * @description
 * This is the application request approvals POC module, it displays a demo app for instagram.
 */
const settingsModule = angular.module('requestApprovalsPOC', [])
.component('requestApprovalsContainer', requestApprovalsContainer)
.constant('SETTINGS_MESSAGES', SETTINGS_MESSAGES);

export default settingsModule.name;
