import { string as sanitizeString } from 'olapic-js-utils/browser/sanitize';

const content = {
  title: 'Content',
  route: '/content',
  config: {
    reloadOnSearch: false,
    requiresPermissions: ['admin.content'],
    template: `
      <library-container></library-container>
    `,
  },
};

/**
 * @ngdoc constant
 * @name NAVIGATION
 * @description
 * Object of navigation elements.
 *
 * @type {object}
 * @property {Array}  avatarItems  The list of avatar {@link AppRoute}.
 * @property {Array}  home         The {@link AppRoute} of the home.
 * @property {object} homeContent  The {@link AppRoute} of the Content section.
 * @property {Array}  mainNav      The list of main navigation {@link AppRoute}.
 * @property {Array}  subNav       The list of secondary navigation {@link AppRoute}.
 * @property {Array}  unmapped     The list of unmapped {@link AppRoute} (routes that don't appear on the menu).
 * @property {Array}  legacy       The list of legacy {@link AppRoute}.
 *
 * @memberof contentEngineAdmin
 */
const navigation = {
  avatarItems: [{
    title: 'Help',
    route: 'https://help.socialnative.com',
    icon: 'fa-solid fa-circle-question',
    target: '_blank',
  }, {
    title: 'Settings',
    route: '/settings',
    icon: 'fa-solid fa-gear',
    showError: true,
    config: {
      reloadOnSearch: false,
      requiresPermissions: ['admin.settings'],
      template: `
        <settings-container></settings-container>
      `,
    },
  }, {
    title: 'Permissions',
    route: '/permissions',
    icon: 'fa-solid fa-list-check',
    target: '_blank',
    config: {
      hideMenu: true,
      // Use the double array so it checks if it has any permission.
      requiresPermissions: [[
        'admin',
        'super_admin',
        'brand_admin',
      ]],
      template: `
        <external-app-container
          app="permissions"
          redirect="true"
        ></external-app-container>
      `,
    },
  }, {
    title: 'Request Approvals',
    route: '/request-approvals',
    icon: 'fa-solid fa-thumbs-up',
    config: {
      // Use the double array so it checks if it has any permission.
      customerId: ['218683'],
      template: `
        <request-approvals-container></request-approvals-container>
      `,
    },
  }, {
    title: 'Switch Account',
    route: '/switch-account',
    icon: 'fa-solid fa-rotate',
    config: {
      // hideMenu: true,
      requiresSwitchAccountEnabled: true,
      template: '<switch-account-container></switch-account-container>',
    },
  }, {
    title: 'Log out',
    route: '/logout',
    icon: 'fa-solid fa-right-from-bracket',
    config: {
      // hideMenu: true,
      redirectPath: '/',
      template: '<logout-container></logout-container>',
    },
  }],
  home: {
    title: 'Social Native',
    route: '//my.socialnative.com/client/order-form/source',
  },
  homeContent: content,
  mainNav: [{
    title: 'Home',
    route: '//my.socialnative.com/client/order-form/source',
    icon: 'fa-solid fa-house-user',
  }, {
    title: 'Source',
    icon: 'fa-solid fa-folder-tree',
    items: [{
      title: 'UGC',
      icon: 'fa-solid fa-mobile-screen-button',
      items: [{
        title: 'Collect',
        items: [{
          title: 'Collections',
          route: '/collections',
          config: {
            requiresPermissions: ['admin.collections'],
            template: `
              <collections-container></collections-container>
            `,
          },
        }, {
          title: 'Content Uploader',
          route: '/content-uploader',
          config: {
            requiresPermissions: ['admin.tools'],
            template: `
              <content-uploader-container></content-uploader-container>
            `,
          },
        }, {
          title: 'Blocked List',
          route: '/blocked-list',
          config: {
            requiresPermissions: ['admin.blacklist'],
            template: `
              <blocked-list-container></blocked-list-container>
            `,
          },
        }],
      }, {
        title: 'Curate',
        items: [
          content, {
            title: 'Streams',
            route: '/streams',
            config: {
              reloadOnSearch: false,
              requiresPermissions: ['admin.medialibrary'],
              template: `
                <external-app-container
                  app="photorank"
                  route="admin/streams2"
                ></external-app-container>
              `,
            },
          }, {
            title: 'Categories',
            route: '/categories',
            config: {
              requiresPermissions: ['admin.moderator'],
              template: `
                <external-app-container
                  app="photorank"
                  route="admin/streams/category?version=2"
                ></external-app-container>
              `,
            },
          }, {
            title: 'Automatic Rights List',
            route: '/automatic-rights-list',
            config: {
              requiresPermissions: ['admin.whitelist'],
              template: `
                <whitelist-container></whitelist-container>
              `,
            },
          }, {
            title: 'Rights Messages',
            route: '/rights-messages',
            config: {
              requiresPermissions: ['admin.settings'],
              template: `
                <rights-messages-container></rights-messages-container>
              `,
            },
          }, {
            title: 'Rights Log',
            route: '/rights-log',
            config: {
              requiresPermissions: ['admin.moderator'],
              template: `
                <external-app-container
                  app="photorank"
                  route="admin/rights"
                ></external-app-container>
              `,
            },
          }],
      }, {
        title: 'Activate',
        items: [{
          title: 'Gallery Manager',
          route: '/gallery-manager',
          config: {
            requiresPermissions: ['admin.developer'],
            template: `
              <external-app-container
                app="ncd"
              ></external-app-container>
            `,
          },
        }, {
          title: 'Widget Design (legacy)',
          route: '/widget-design',
          config: {
            requiresPermissions: ['admin.developer'],
            template: `
              <external-app-container
                app="sandbox"
                route="editor"
              ></external-app-container>
            `,
          },
        }, {
          title: 'Widget Management (legacy)',
          route: '/widget-management',
          config: {
            requiresPermissions: ['admin.developer'],
            template: `
              <external-app-container
                app="photorank"
                route="admin/widgets"
              ></external-app-container>
            `,
          },
        }, {
          title: 'E-mail',
          route: '/email',
          config: {
            requiresPermissions: ['admin.developer'],
            template: `
              <external-app-container
                app="emailStudio"
              ></external-app-container>
            `,
          },
        }],
      }, {
        title: 'Analyze',
        config: {
          requiresPermissions: ['admin.analytics'],
        },
        items: [{
          title: 'Analytics',
          route: '/analytics',
          config: {
            requiresPermissions: ['admin.analytics'],
            template: `
              <external-app-container
                app="lemuramaAnalytics"
                route="analytics"
              ></external-app-container>
            `,
          },
        }, {
          title: 'Advocates',
          route: '/advocates',
          config: {
            requiresPermissions: ['admin.analytics'],
            template: `
              <advocates-container></advocates-container>
            `,
          },
        }, {
          title: 'Dashboard',
          route: '/dashboard',
          config: {
            requiresPermissions: ['admin.analytics'],
            template: `
              <external-app-container
                app="lemuramaAnalytics"
                route="welcome"
              ></external-app-container>
            `,
          },
        }],
      }],
    }, {
      title: 'Creator content',
      icon: 'fa-solid fa-users',
      items: [{
        title: 'Dashboards',
        route: '//my.socialnative.com/client/creator-search/',
      }, {
        title: 'Campaigns',
        route: '//my.socialnative.com/admin/dashboard',
      }, {
        title: 'Creator Discovery',
        route: '//my.socialnative.com/client/orders/dashboard/',
      }],
    }, {
      title: 'Upload Content',
      icon: 'fa-solid fa-arrow-up-from-bracket',
      route: '/content-uploader',
      config: {
        requiresPermissions: ['admin.tools'],
        template: `
          <content-uploader-container></content-uploader-container>
        `,
      },
    },
    {
      title: 'AI',
      icon: 'fa-solid fa-brain',
      soon: true,
    }],
  }, {
    title: 'Distribute',
    icon: 'fa-solid fa-paper-plane',
    items: [{
      title: 'Create a Gallery',
      icon: 'fa-solid fa-grip',
      route: '/gallery-manager',
      config: {
        requiresPermissions: ['admin.developer'],
        template: `
          <external-app-container
            app="ncd"
          ></external-app-container>
        `,
      },
    }, {
      title: 'Create an Ad',
      icon: 'fa-solid fa-mobile-screen-button',
      route: '//my.socialnative.com/admin/brands',
      config: {
        requiresPermissions: ['admin.developer'],
      },
    }, {
      title: 'Email Campaigns',
      icon: 'fa-solid fa-envelope',
      route: '/email',
      config: {
        requiresPermissions: ['admin.developer'],
        template: `
          <external-app-container
            app="emailStudio"
          ></external-app-container>
        `,
      },
    }],
  },
  {
    title: 'Measure',
    icon: 'fa-solid fa-chart-column',
    items: [{
      title: 'Global Analytics',
      route: '//my.socialnative.com/client/global-analytics',
      icon: 'fa-solid fa-ranking-star',
      soon: false,
    }],
  },
  {
    title: 'Admin',
    icon: 'fa-solid fa-user-gear',
    items: [{
      title: 'Brands',
      route: '//my.socialnative.com/admin/brands',
    },
    {
      title: 'Creator Discovery',
      route: '//my.socialnative.com/client/creator-search',
    },
    {
      title: 'Creator CRM',
      route: '//my.socialnative.com/leads',
    },
    {
      title: 'Data Explorer',
      route: '//my.socialnative.com/data-explorer',
    },
    {
      title: 'Due Payouts',
      route: '//my.socialnative.com/admin/duePayouts',
    },
    {
      title: 'User Management',
      route: '//my.socialnative.com/admin/userManagement',
    },
    {
      title: 'Admin Exports',
      route: '//my.socialnative.com/admin/reports',
    },
    {
      title: 'Operations',
      route: '//my.socialnative.com/operations',
    }],
    config: {
      requiresPermissions: [[
        'admin',
        'super_admin',
      ]],
    },
  }],
  subNav: [{
    title: 'Help',
    route: 'https://help.photorank.me/',
    target: '_blank',
  }],
  unmapped: [{
    route: '/login',
    config: {
      fallbackURL: '/',
      // hideMenu: true,
      requiresAnonymous: true,
      template: '<login-container></login-container>',
    },
  }, {
    route: '/help/:articleId',
    target: '_blank',
    config: {
      hideMenu: true,
      /**
       * Create the template with the sanitized articleId.
       *
       * @param {string} [articleId='']  The articleId to sanitize.
       *
       * @returns {string}
       */
      template: ({ articleId = '' }) => `
        <external-app-container
          app="zendesk"
          article-id="${sanitizeString(articleId)}"
        ></external-app-container>
      `,
    },
  }, {
    route: '/unauthorized',
    config: {
      template: '<unauthorized-container></unauthorized-container>',
    },
  }],
  legacy: [{
    route: '/admin/settings',
    config: { redirectTo: '/settings' },
  }, {
    route: '/admin/accounts',
    config: { redirectTo: '/switch-account' },
  }, {
    route: '/admin/logout',
    config: { redirectTo: '/logout' },
  }, {
    route: '/admin/collectors',
    config: { redirectTo: '/collections' },
  }, {
    route: '/admin/tools/contentuploader',
    config: { redirectTo: '/content-uploader' },
  }, {
    route: '/admin/tools/blacklist',
    config: { redirectTo: '/blocked-list' },
  }, {
    route: '/admin/content',
    config: { redirectTo: '/content' },
  }, {
    route: '/admin/streams2',
    config: { redirectTo: '/streams' },
  }, {
    route: '/admin/streams/category',
    config: { redirectTo: '/categories' },
  }, {
    route: '/admin/whitelist',
    config: { redirectTo: '/automatic-rights-list' },
  }, {
    route: '/admin/rightsmessages',
    config: { redirectTo: '/rights-messages' },
  }, {
    route: '/admin/rights',
    config: { redirectTo: '/rights-log' },
  }, {
    route: '/admin/scheduler',
    config: { redirectTo: '/scheduled-content' },
  }, {
    route: '/admin/tapshop',
    config: { redirectTo: '/tapshop-manager' },
  }, {
    route: '/editor',
    config: { redirectTo: '/widget-design' },
  }, {
    route: '/admin/widgets',
    config: { redirectTo: '/widget-management' },
  }, {
    route: '/admin/email',
    config: { redirectTo: '/email' },
  }, {
    route: '/admin/analytics-v2',
    config: { redirectTo: '/analytics' },
  }, {
    route: '/admin/advocates',
    config: { redirectTo: '/advocates' },
  }, {
    route: '/admin/dashboard-v2',
    config: { redirectTo: '/dashboard' },
  }, {
    route: '/admin/support/login',
    config: { redirectTo: '/help' },
  }, {
    route: '/admin/docs',
    config: { redirectTo: '/help' },
  }, {
    route: '/admin/login',
    config: { redirectTo: '/login' },
  }],
};
export default navigation;
