import statuses from 'statuses';

/**
 * @ngdoc controller
 * @name BlockedListContainer
 * @description
 * This container displays the blocked list section.
 *
 * @memberof blockedList
 */
class BlockedListContainer {
  /**
   * @param {$q}              $q                   To wait for several promises with the all method.
   * @param {$filter}         $filter              To filter the blocked list.
   * @param {Object}          blockedHashtagsList  To fetch the blocked hashtags.
   * @param {Object}          blockedUsersList     To fetch the blocked users.
   * @param {UIMessages}      uiMessages           To display the error messages.
   */
  constructor(
    $q,
    $filter,
    blockedHashtagsList,
    blockedUsersList,
    uiMessages,
  ) {
    'ngInject';

    /**
     * The local reference to the `$q` service.
     *
     * @type {$q}
     */
    this.$q = $q;
    /**
     * The local reference to the `$filter` service.
     *
     * @type {$filter}
     */
    this.$filter = $filter;
    /**
     * The local reference to the `blockedHashtagsList` service.
     *
     * @type {BlockedHashtagsList}
     */
    this.blockedHashtagsList = blockedHashtagsList.getNewInstance();
    /**
     * The local reference to the `blockedUsersList` service.
     *
     * @type {BlockedUsersList}
     */
    this.blockedUsersList = blockedUsersList.getNewInstance();
    /**
     * The list of blocked hashtags and users.
     *
     * @type {Array}
     */
    this.blockedList = [];
    /**
     * The text to search the blocked listfor.
     *
     * @type {string}
     */
    this.search = '';
    /**
     * The list of blocked hashtags and users after pagination.
     *
     * @type {Array}
     */
    this.paginatedBlockedList = [];
    /**
     * The pagination information.
     *
     * @type {Object}
     */
    this.pagination = {
      from: 0,
      to: 0,
      total: 0,
      next: false,
      pageSize: 10,
      prev: false,
    };
    /**
     * The local reference to the `uiMessages` service.
     *
     * @type {UIMessages}
     */
    this.uiMessages = uiMessages;
  }
  /**
   * Get the blocked list.
   */
  $onInit() {
    this.$q.all([
      this.blockedUsersList.getBlockedUsers(),
      this.blockedHashtagsList.getBlockedHashtags(),
    ])
    .then(() => this._arrangeBlockedList())
    .catch(() => {
      this.uiMessages.notification(
        'Sorry, there was a problem while loading your blocked list. Please try again later.',
        { type: 'error' },
      );
    });
  }
  /**
   * Check if we should display the loading indicator.
   *
   * @returns {boolean}
   */
  isLoading() {
    return this.blockedHashtagsList.loading ||
      this.blockedUsersList.loading;
  }
  /**
   * Callback to block a hashtags list.
   *
   * @param {Array} hashtagsList  The list of hashtags to block.
   */
  onBlockHashtagsList(hashtagsList) {
    this.blockedHashtagsList.blockHashtagsList(hashtagsList)
    .then((response) => {
      if (response) {
        const successBlockedHashtags = response.blocked_hashtags;
        const failedBlockedHashtags = response.failed_hashtags;

        if (successBlockedHashtags.length) {
          const successBlockedHashtagsMessage = successBlockedHashtags.length === 1 ?
            `The hashtag <strong>#${successBlockedHashtags[0]}</strong> has been blocked.` :
            `<strong>${successBlockedHashtags.length}</strong> hashtags have been blocked.`;
          this.uiMessages.notification(successBlockedHashtagsMessage, { type: 'info' });
        }

        if (failedBlockedHashtags.length) {
          const failedBlockedHashtagsMessage = failedBlockedHashtags.length === 1 ?
            `The hashtag <strong>#${failedBlockedHashtags[0]}</strong> is already blocked.` :
            `<strong>${failedBlockedHashtags.length}</strong> hashtags are already blocked.`;
          this.uiMessages.notification(failedBlockedHashtagsMessage, { type: 'info' });
        }

        if (!successBlockedHashtags.length && !failedBlockedHashtags.length) {
          this.uiMessages.notification(
            'Sorry, we could not block your hashtags. Please try again.',
            { type: 'error' },
          );
        }

        this.blockedHashtagsList.getBlockedHashtags()
        .then(() => this._arrangeBlockedList(this.pagination.from))
        .catch(() => {
          this.uiMessages.notification(
            'Sorry, there was a problem while loading your blocked list. Please try again later.',
            { type: 'error' },
          );
        });
      }
    })
    .catch(() => {
      this.uiMessages.notification(
        'Sorry, we could not block your hashtags. Please try again.',
        { type: 'error' },
      );
    });
  }
  /**
   * Callback to block a user.
   *
   * @param {string} username  The username to block.
   * @param {string} source    The source of the user to block.
   */
  onBlockUser(username, source) {
    const user = {
      username: username.toLowerCase(),
      source,
    };

    this.blockedUsersList.blockUsername(user)
    .then(() => {
      this.uiMessages.notification(`The user <strong>@${username}</strong> has been blocked.`,
        { type: 'info' });

      this.blockedUsersList.getBlockedUsers()
      .then(() => this._arrangeBlockedList(this.pagination.from))
      .catch(() => {
        this.uiMessages.notification(
          'Sorry, there was a problem while loading your blocked list. Please try again later.',
          { type: 'error' },
        );
      });
    })
    .catch((error) => {
      const message = error.status === statuses.conflict ?
        `The user <strong>@${username}</strong> is already blocked.` :
        `Sorry, we could not block user <strong>@${username}</strong>. Please try again.`;

      this.uiMessages.notification(
        message,
        { type: 'error' },
      );
    });
  }
  /**
   * Callback when the pagination `Next` button is clicked.
   * It will filter the next Blocked items page.
   *
   * @throws Error.
   */
  onPaginationNextClick() {
    if (!this.pagination.next) {
      throw new Error('Unable to fetch the previous page');
    }

    this._paginateBlockedList(this.pagination.from + this.pagination.pageSize);
  }
  /**
   * Callback when the pagination `Previous` button is clicked.
   * It will filter the previous Blocked items page.
   *
   * @throws Error.
   */
  onPaginationPreviousClick() {
    if (!this.pagination.prev) {
      throw new Error('Unable to fetch the next page');
    }

    this._paginateBlockedList(this.pagination.from - this.pagination.pageSize);
  }
  /**
   * Filter the blocked list according to the search text.
   *
   * @param {string} search  The search text to filter the blocked list.
   */
  onSearchBlockedList(search) {
    this.search = search;
    this._arrangeBlockedList();
  }
  /**
   * Remove an user or a hashtag from the blocked list.
   *
   * @param {Object} item  The item to delete from blocked list.
   */
  onUnblockItem(item) {
    if (item.username) {
      this.blockedUsersList.unblockUser(item)
      .then(() => {
        this.uiMessages.notification(
          `The username <strong>@${item.username}</strong> has been unblocked.`,
          { type: 'info' },
        );
        this._arrangeBlockedList(this.pagination.from);
      })
      .catch(() => {
        this.uiMessages.notification(
          `Sorry, we could not block the username <strong>@${item.hashtag}</strong>. Please try again.`,
          { type: 'error' },
        );
      });
    } else {
      this.blockedHashtagsList.unblockHashtag(item)
      .then(() => {
        this.uiMessages.notification(
          `The hashtag <strong>#${item.hashtag}</strong> has been unblocked.`,
          { type: 'info' },
        );
        this._arrangeBlockedList(this.pagination.from);
      })
      .catch(() => {
        this.uiMessages.notification(
          `Sorry, we could not block the hashtag <strong>#${item.hashtag}</strong>. Please try again.`,
          { type: 'error' },
        );
      });
    }
  }
  /**
   * Arrange the blocked users and hashtags list.
   *
   * @param {number} start  The starting point to set the pagination to.
   */
  _arrangeBlockedList(start = 1) {
    this.blockedItems = this.$filter('filter')(
      [
        ...this.blockedUsersList.entities,
        ...this.blockedHashtagsList.entities,
      ],
      { $: this.search },
    );
    this.blockedItems = this.$filter('orderBy')(
      this.blockedItems,
      'value',
    );
    this._paginateBlockedList(start);
  }
  /**
   * Paginate the blocked users and hashtags list.
   *
   * @param {number} start  The starting point to set the pagination to.
   */
  _paginateBlockedList(start = 1) {
    const from = start > this.blockedItems.length ?
      start - this.pagination.pageSize :
      start;
    const to = Math.min((from + this.pagination.pageSize) - 1, this.blockedItems.length);

    this.pagination = {
      from,
      to,
      total: this.blockedItems.length,
      next: to !== this.blockedItems.length,
      pageSize: this.pagination.pageSize,
      prev: from !== 1,
    };

    this.paginatedBlockedList = this.$filter('limitTo')(
      this.blockedItems,
      this.pagination.pageSize,
      from - 1,
    );
  }
}

/**
 * @ngdoc component
 * @name blockedListContainer
 * @description
 * The blocked list container.
 *
 * @memberof blockedList
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {BlockedListContainer}
   */
  controller: BlockedListContainer,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template: `
    <div
      class="odsSmallIndicator -fullScreen"
      ng-if="$ctrl.isLoading()"
    ></div>
    <blocked-list
      blocked-items="$ctrl.paginatedBlockedList"
      pagination="$ctrl.pagination"
      search="$ctrl.search"
      on-pagination-next-click="$ctrl.onPaginationNextClick()"
      on-pagination-previous-click="$ctrl.onPaginationPreviousClick()"
      on-search="$ctrl.onSearchBlockedList(search)"
      on-block-hashtags-list="$ctrl.onBlockHashtagsList(hashtagsList)"
      on-block-user="$ctrl.onBlockUser(username, source)"
      on-unblock-item="$ctrl.onUnblockItem(item)"
    ></blocked-list>
  `,
};
