/**
 * @ngdoc constant
 * @name COLLECTIONS_ACTIONS
 * @description
 * The list of actions for collections.
 *
 * @type {Object}
 * @property {CollectionAction} status   The change status collection action.
 * @property {CollectionAction} delete   The delete collection action.
 * @property {CollectionAction} edit     The edit collection action.
 *
 * @memberof collections
 */
const collectionsActions = {
  duplicate: {
    id: 'duplicate',
    name: 'Duplicate',
  },
  delete: {
    id: 'delete',
    name: 'Delete',
  },
  edit: {
    id: 'edit',
    name: 'Edit',
  },
};

export default collectionsActions;
