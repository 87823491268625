import template from './blockedList.html';
import './blockedList.scss';

/**
 * @ngdoc controller
 * @name BlockedList
 * @description
 * This component displays the blocked list section.
 *
 * @memberof blockedList
 */
class BlockedList {
  constructor() {
    /**
     * Flag to indicate if the hashtag input has errors.
     *
     * @type {boolean}
     */
    this.hashtagError = false;
    /**
     * The hashtags to add to blocked list.
     *
     * @type {string}
     */
    this.hashtagsToBlock = '';
    /**
     * Flag to indicate if the username input has errors.
     *
     * @type {boolean}
     */
    this.usernameError = false;
    /**
     * The username to add to blocked list.
     *
     * @type {string}
     */
    this.usernameToBlock = '';
    /**
     * The username source to add to blocked list.
     *
     * @type {string}
     */
    this.usernameSource = 'instagram';
    /**
     * The username length limit for each social network.
     *
     * @type {Object}
     */
    this.usernamesLimits = {
      instagram: 31,
      twitter: 16,
    };
    /**
     * The validation masks to compare with hashtags and usernames inputs.
     *
     * @type {Object}
     */
    this.validationMasks = {
      hashtagsAllowedCharsRegExp: /^([#\w,\s]|[#\w,#])*$/,
      hashtagsInvalidCharPositionRegExp: /([\w|#][#])/,
      hashtagsInvalidCharRegExp: /^(#\s)+/,
      instagramUsernameAllowedCharsRegExp: /^[@\w.-]*$/,
      twitterUsernameAllowedCharsRegExp: /^[@\w]*$/,
      usernameRepeteadCharsRegExp: /([@](@)\1)/,
      usernameInvalidCharPositionRegExp: /([\w.][@])/,
    };
  }
  /**
   * Add a hashtag to the blocked list.
   */
  onBlockHashtags() {
    if (this._validateHastagsList()) {
      const sanitizedHashtagsList = this._sanitizeHashtags(this.hashtagsToBlock);
      this.onBlockHashtagsList({ hashtagsList: sanitizedHashtagsList });
      this.hashtagsToBlock = '';
    }
  }
  /**
   * Add a username to the blocked list.
   */
  onBlockUsername() {
    if (
      this.usernameSource === 'instagram' ?
        this._validateHastagsList() :
        this._validateTwitterUsername()
    ) {
      const sanitizedUsername = this._sanitizeUsername(this.usernameToBlock);
      this.onBlockUser({
        username: sanitizedUsername,
        source: this.usernameSource,
      });
      this.usernameToBlock = '';
    }
  }
  /**
   * Remove the '#' to the input when the user removes focus from the hashtag input.
   */
  onHashtagBlur() {
    this.hashtagsToBlock = this.hashtagsToBlock.length > 1 ? this.hashtagsToBlock : '';
  }
  /**
   * Add a '#' to the input when the user focus on hashtag input.
   */
  onHashtagFocus() {
    this.hashtagsToBlock = this.hashtagsToBlock.length > 1 ? this.hashtagsToBlock : '#';
  }
  /**
   * Called when there is a change in the username input.
   * It validates the username is valid for each case and disable the block action if is not.
   */
  onHashtagInputChange() {
    this.hashtagError = !this._validateHastagsList();
  }
  /**
   * Remove the '@' to the input when the user removes focus from the username input.
   */
  onUsernameBlur() {
    this.usernameToBlock = this.usernameToBlock.length > 1 ? this.usernameToBlock : '';
  }
  /**
   * Add a '@' to the input when the user focus on username input.
   */
  onUsernameFocus() {
    this.usernameToBlock = this.usernameToBlock.length > 1 ? this.usernameToBlock : '@';
  }
  /**
   * Called when there is a change in the username input.
   * It validates the username is valid for each case and disable the block action if is not.
   */
  onUsernameInputChange() {
    this.usernameError = this.usernameSource === 'instagram' ?
      !this._validateInstagramUsername() :
      !this._validateTwitterUsername();
  }
  /**
   * Parse a list of hashtags in order to fulfill with a hashtag spec.
   *
   * @param {string} hashtagsList  Contains the string with hashtags.
   *
   * @returns {Array}
   */
  _sanitizeHashtags(hashtagsList) {
    return hashtagsList
    .replace(/ /g, ',')
    // Remove hashtags chars.
    .replace(/(#)/g, '')
    // Split the list using commas.
    .split(',')
    // Remove any leading and/or trailing spaces from the hashtags.
    .map((hashtag) => hashtag.trim())
    // Remove empty strings.
    .filter(Boolean);
  }
  /**
   * Sanitize the username to block in order to fulfill with a username spec.
   *
   * @param {string} username  Contains the string with the username to block.
   *
   * @returns {string}
   */
  _sanitizeUsername(username) {
    return username
    // Remove mentions chars.
    .replace(/(@)/g, '')
    // Remove any leading and/or trailing spaces from the names.
    .trim();
  }
  /**
   * Validate the user's input in order to set the error message value
   * in case the input is invalid. It Checks:
   * - Only allowed characters (letters, numbers, period and underscore, hash, comma).
   * - No hash allowed at the end or in other position than the beginning of a hashtag.
   *
   * @returns {boolean}
   *
   * @access protected
   */
  _validateHastagsList() {
    return this.validationMasks.hashtagsAllowedCharsRegExp.test(this.hashtagsToBlock) &&
      !this.validationMasks.hashtagsInvalidCharPositionRegExp.test(this.hashtagsToBlock) &&
      !this.validationMasks.hashtagsInvalidCharRegExp.test(this.hashtagsToBlock);
  }
  /**
   * Validate the user's input for instagram in order to set the error message value
   * in case the input is invalid. It Checks:
   * - Only allowed characters (letters, numbers, period and underscore, mentions).
   * - No repetition for mention.
   * - No mention allowed at the end of an username.
   *
   * @returns {boolean}
   *
   * @access protected
   */
  _validateInstagramUsername() {
    return this.validationMasks.instagramUsernameAllowedCharsRegExp.test(this.usernameToBlock) &&
      !this.validationMasks.usernameRepeteadCharsRegExp.test(this.usernameToBlock) &&
      !this.validationMasks.usernameInvalidCharPositionRegExp.test(this.usernameToBlock) &&
      this.usernameToBlock.length <= this.usernamesLimits.instagram;
  }
  /**
   * Validate the user's input for twitter in order to set the error message value
   * in case the input is invalid. It Checks:
   * - Only allowed characters (letters, numbers, underscore, mentions).
   * - No repetition for mention.
   * - No mention allowed at the end of an username.
   *
   * @returns {boolean}
   *
   * @access protected
   */
  _validateTwitterUsername() {
    return this.validationMasks.twitterUsernameAllowedCharsRegExp.test(this.usernameToBlock) &&
      !this.validationMasks.usernameRepeteadCharsRegExp.test(this.usernameToBlock) &&
      !this.validationMasks.usernameInvalidCharPositionRegExp.test(this.usernameToBlock) &&
      this.usernameToBlock.length <= this.usernamesLimits.twitter;
  }
}

/**
 * @ngdoc component
 * @name blockedList
 * @description
 * This component renders the blocked list section.
 *
 * @memberof blockedList
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {BlockedList}
   */
  controller: BlockedList,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {Array}      blockedItems               The list of blocked items.
   * @property {Pagination} pagination                 The pagination information.
   * @property {string}     search                     The search string.
   * @property {Function}   onPaginationNextClick      Callback for when the pagination next button is clicked.
   * @property {Function}   onPaginationPreviousClick  Callback for when the pagination previous button is clicked.
   * @property {Function}   onSearch                   Callback for when the user perform a search on the blocked list.
   * @property {Function}   onBlockHashtagsList        Callback to block a hashtags list.
   * @property {Function}   onBlockUser                Callback to block a username.
   * @property {Function}   onUnblockItem              Callback to unblock a blocked item.
   *
   */
  bindings: {
    blockedItems: '<',
    pagination: '<',
    search: '<',
    onPaginationNextClick: '&',
    onPaginationPreviousClick: '&',
    onSearch: '&',
    onBlockHashtagsList: '&',
    onBlockUser: '&',
    onUnblockItem: '&',
  },
};
