import template from './whitelistTable.html';
import './whitelistTable.scss';

import noResults from '../../../../assets/images/automaticRightsNoResults.svg';

/**
 * @ngdoc controller
 * @name WhitelistTable
 * @description
 * This component renders the whitelist table.
 *
 * @memberof whitelist
 */
class WhitelistTable {
  constructor() {
    /**
     * The dates range for whitelist users.
     *
     * @type {Object}
     */
    this.dates = {};
    /**
     * The edited whitelist user's labels.
     *
     * @type {string}
     */
    this.labels = '';
    /**
     * Amount of label suggestions to show.
     *
     * @type {number}
     */
    this.suggestionsLimit = 5;
    /**
     * Reference to the user to be edited.
     *
     * @type {?Object}
     */
    this.userToEdit = null;
    /**
     * Reference to the noResults image.
     *
     * @type {string}
     */
    this.noResults = noResults;
    /**
     * If the edit modal is visible or not.
     *
     * @type {boolean}
     */
    this.isModalVisible = false;
  }
  /**
   * Get the labels to display in the bulk edit modal.
   *
   * @returns {string}
   */
  getSelectedUsersLabels() {
    const [firstSelectedUser] = this.whitelistUsers.filter((user) => (
      `${user.id}` === Object.keys(this.selectedWhitelistUsers)[0]
    ));
    return firstSelectedUser.labelsDisplay;
  }
  /**
   * Set the corresponding date related to the form data.
   *
   * @param {string} date   The type of date.
   * @param {Date}   value  The date value.
   */
  onDateSet(date, value) {
    this.dates[date] = value;
  }
  /**
   * Callback triggered when the label input changes.
   *
   * @param {string} labels  The labels to set.
   */
  onLabelsSet(labels = '') {
    this.labels = labels;
    this.onSearchLabelSuggestions({
      search: this.labels,
      limit: this.suggestionsLimit,
    });
  }
  /**
   * Callback triggered when a label is being selected for strict search.
   *
   * @param {string} label  The label to search.
   */
  onLabelStrictSearch(label) {
    const labelSearchCriteria = this.searchCriteria.find((criteria) => criteria.name === 'label');

    this.onSearchWhitelistUsers({
      search: label,
      criteria: labelSearchCriteria,
      force: true,
      strict: true,
    });
  }
  /**
   * Hides the user edit modal.
   */
  onModalClose() {
    this.isModalVisible = false;
    this.onBulkModalClose();
  }
  /**
   * Callback for when saving the changes.
   *
   * @param {Object} user  The user or users to save.
   */
  onSaveUserEdit(user) {
    this.onSaveWhitelistUsers(user);
    this.onModalClose();
  }
  /**
   * Callback when the user edit button is clicked.
   *
   * @param {Object} user  The user to edit.
   */
  onUserEdit(user) {
    this.isModalVisible = true;
    this.userToEdit = user;
  }
}

/**
 * @ngdoc component
 * @name whitelistTable
 * @description
 * It defines the table to manage whitlisted users.
 *
 * @memberof whitelist
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {WhitelistTable}
   */
  controller: WhitelistTable,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {boolean}    isBulkModalVisible                 If the bulk modal is visible or not.
   * @property {boolean}    isSelectedAllWhitelistUsers        If the all the whitelist users are selected or not.
   * @property {Array}      labelSuggestions                   The list of label suggestions to display.
   * @property {boolean}    loading                            If a request for the whitelist is in progress.
   * @property {Pagination} pagination                         The pagination information.
   * @property {string}     search                             The text of the search box.
   * @property {Array}      searchCriteria                     The list of available search type.
   * @property {Object}     selectedSearchCriteria             The selected element from the search type options.
   * @property {Object}     selectedSortType                   The selected element from the sort by options.
   * @property {Object}     selectedStatus                     The selected element from the filter by status.
   * @property {Object}     selectedWhitelistUsers             The map of selected whitelist users.
   * @property {number}     selectedWhitelistUsersCount        The count of selected whitelist users.
   * @property {boolean}    showLabelInput                     Indicates to show or not, the label input.
   * @property {Array}      sortTypes                          The list of sort types available.
   * @property {Array}      userStatuses                       The list of statuses to filter.
   * @property {Array}      whitelistUsers                     The list of whitelist users to display.
   * @property {Function}   onBulkModalClose                   Callback for when the bulk modal is closed.
   * @property {Function}   onDeleteWhitelistUser              Callback for when we want to delete a whitelist user. It
   *                                                           receives the whitelist user to delete.
   * @property {Function}   onPaginationNextClick              Callback for when the pagination next button is clicked.
   * @property {Function}   onPaginationPreviousClick          Callback for when the pagination previous
   *                                                           button is clicked.
   * @property {Function}   onSaveWhitelistUsers               Callback for when we want to add a user to the list.
   * @property {Function}   onSearchLabelSuggestions           Callback for when we want to search label suggestions.
   * @property {Function}   onSearchWhitelistUsers             Callback for when we want to search whitelist user by
   *                                                           username. It receives the search to make.
   * @property {Function}   onSortingChange                    Callback for when selecting a sorting type.
   * @property {Function}   onStatusChange                     Callback for when selecting a status type.
   * @property {Function}   onWhitelistUserSelectedChange      Callback when the selection of a user is changed. It
   *                                                           receives the user, if it was selected or unselected and
   *                                                           if we are editing or not.
   * @property {Function}   onWhitelistAllUsersSelectedChange  Callback when the selection of a user is changed. It
   *                                                           receives the user, if it was selected or unselected and
   *                                                           if we are editing or not.
   */
  bindings: {
    isBulkModalVisible: '<',
    isSelectedAllWhitelistUsers: '<',
    labelSuggestions: '<',
    loading: '<',
    pagination: '<',
    search: '<',
    searchCriteria: '<',
    selectedSearchCriteria: '<',
    selectedSortType: '<',
    selectedStatus: '<',
    selectedWhitelistUsers: '<',
    selectedWhitelistUsersCount: '<',
    showLabelInput: '<',
    sortTypes: '<',
    statusTypes: '<',
    whitelistUsers: '<',
    onBulkModalClose: '&',
    onDeleteWhitelistUser: '&',
    onPaginationNextClick: '&',
    onPaginationPreviousClick: '&',
    onSaveWhitelistUsers: '&',
    onSearchLabelSuggestions: '&',
    onSearchWhitelistUsers: '&',
    onSortingChange: '&',
    onStatusChange: '&',
    onWhitelistUserSelectedChange: '&',
    onWhitelistAllUsersSelectedChange: '&',
  },
};
