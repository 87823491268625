import template from './socialAccounts.html';
import './socialAccounts.scss';

/**
 * @ngdoc controller
 * @name SocialAccounts
 * @description
 * This component renders the list of connected social accounts.
 *
 * @memberof settings
 */
class SocialAccounts {
  constructor() {
    /**
     * If Instagram accounts are visible or not.
     *
     * @type {boolean}
     */
    this.areInstagramAccountsVisible = true;
    /**
     * The filtered Social Mention Accounts list.
     *
     * @type {Array}
     */
    this.filteredSocialMentionsAccounts = [];
    /**
     * The filtered Social Accounts list.
     *
     * @type {Object}
     */
    this.filteredSocialAccounts = {
      pinterest: [],
      twitter: [],
    };
    /**
     * The number of child accounts with some problem. This is displayed in the template.
     *
     * @type {number}
     */
    this.numberOfChildAccountsWithProblems = 0;
    /**
     * If the details are visible or not.
     *
     * @type {boolean}
     */
    this.showDetails = false;
    /**
     * Whether the warning message is displayed or not.
     *
     * @type {boolean}
     */
    this.showWarningMessage = false;
    /**
     * The the search text to filter the accounts list.
     *
     * @type {string}
     */
    this.search = '';
  }
  /**
   * Each time the socialAccounts binding changes, update the filteredSocialAccounts variables.
   * Each time the socialMentionsAccounts binding changes, get the number of child accounts
   * that has at least one functionality inactive and update the filteredSocialMentionsAccounts variable.
   *
   * @param {Object} changes                         The binding changes.
   * @param {Object} changes.socialAccounts          The socialAccounts change object.
   * @param {Object} changes.socialMentionsAccounts  The socialMentionsAccounts change object.
   */
  $onChanges({ socialAccounts, socialMentionsAccounts }) {
    if (
      socialAccounts &&
      socialAccounts.currentValue
    ) {
      this.filteredSocialAccounts.pinterest = this.socialAccounts.pinterest;
      this.filteredSocialAccounts.twitter = this.socialAccounts.twitter;
      this.filterAccountsList(this.search);
    }

    if (
      socialMentionsAccounts &&
      socialMentionsAccounts.currentValue
    ) {
      this.numberOfChildAccountsWithProblems = this.socialMentionsAccounts
      .reduce((problemsCounter, account) => (
        !account.allActive ?
          problemsCounter + 1 :
          problemsCounter
      ), 0);
      this.showWarningMessage = this.numberOfChildAccountsWithProblems !== 0;

      // Sort first the ones with errors.
      this.filteredSocialMentionsAccounts = this.socialMentionsAccounts
      .sort((a, b) => {
        if (!a.allActive && b.allActive) {
          return -1;
        }
        if (a.allActive && !b.allActive) {
          return 1;
        }
        return 0;
      });
      this.filterAccountsList(this.search);
    }
  }
  /**
   * Filter the accounts list according to the search text.
   *
   * @param {string} search  The search text to filter accounts list.
   */
  filterAccountsList(search) {
    this.search = search;

    this.filteredSocialMentionsAccounts = this.socialMentionsAccounts ?
      this.socialMentionsAccounts.filter(
        (socialMentionAccount) => socialMentionAccount.username.includes(this.search),
      ) :
      this.socialMentionsAccounts;

    this.filteredSocialAccounts.pinterest = this.socialAccounts.pinterest ?
      this.socialAccounts.pinterest.filter(
        (pinterestAccount) => pinterestAccount.handle.username.includes(this.search),
      ) :
      this.socialMentionsAccounts.pinterest;

    this.filteredSocialAccounts.twitter = this.socialAccounts.twitter ?
      this.socialAccounts.twitter.filter(
        (twitterAccount) => twitterAccount.handle.username.includes(this.search),
      ) :
      this.socialMentionsAccounts.twitter;
  }
  /**
   * Hides the warning message.
   */
  onCloseWarningMessage() {
    this.showWarningMessage = false;
  }
  /**
   * Callback for when accounts with child accounts are collapsed.
   * If there are no social mentions accounts do not allow to collapse.
   * If the Instagram accounts and details are visible, we need to
   * toggle the visibility of the details.
   */
  onCollapseAccounts() {
    if (!this.filteredSocialMentionsAccounts.length) {
      return;
    }

    if (this.areInstagramAccountsVisible && this.showDetails) {
      this.onToggleDetails();
    }

    this.areInstagramAccountsVisible = !this.areInstagramAccountsVisible;
  }
  /**
   * Toggle the visibility of the details.
   * If the Instagram accounts and details are not visible, we need to
   * collapse the accounts.
   *
   * @param {Event} event  The button click event.
   */
  onToggleDetails(event) {
    if (event) {
      event.stopPropagation();
    }

    if (!this.areInstagramAccountsVisible && !this.showDetails) {
      this.onCollapseAccounts();
    }

    this.showDetails = !this.showDetails;
  }
}

/**
 * @ngdoc component
 * @name socialAccounts
 * @description
 * This component renders the social accounts list.
 *
 * @memberof settings
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {SocialAccounts}
   */
  controller: SocialAccounts,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {Object}   facebookPages             The facebook pages associated with this customer.
   * @property {boolean}  hasFacebookAccount        If the customer has a Facebook account connected.
   * @property {Object}   socialAccounts            The social accounts associated with this customer.
   * @property {Object}   socialAccountsErrorState  Map of the social accounts error state.
   * @property {Object}   socialMentionsAccounts    The social mentions accounts associated with this customer.
   * @property {Function} onConnect                 Callback to open the connection flow.
   * @property {Function} onRemoveSocialAccount     Callback to delete a social account from customer.
   */
  bindings: {
    facebookPages: '<',
    hasFacebookAccount: '<',
    socialAccounts: '<',
    socialAccountsErrorState: '<',
    socialMentionsAccounts: '<',
    onConnect: '&',
    onRemoveSocialAccount: '&',
  },
};
