/**
 * @ngdoc overview
 * @name setToken
 * @description
 * When the app session is loaded or created, we need to update all API with the correct token.
 *
 * @param {$rootScope}                   $rootScope                    To remove the listeners on the $destroy event.
 * @param {AnalyticsExportAPI}           analyticsExportAPI            To set the authorization token and customerId.
 * @param {AppAPI}                       appAPI                        To set the authorization token and customerId.
 * @param {AppSession}                   appSession                    To subscribe for the app session events.
 * @param {CustomerAvatarUploader}       customerAvatarUploader        To set the authorization token and customerId.
 * @param {LaunchDarkly}                 launchDarkly                  To initialize LaunchDarkly.
 * @param {Heap}                         heap                          To identify the user in Heap Analytics.
 * @param {Intercom}                     intercom                      To identify the user in Intercom.
 * @param {RightsExtension}              rightsExtension               To set up the auth data.
 * @param {SocialAccountsWithStatusList} socialAccountsWithStatusList  To start the social accounts loading process.
 * @param {Tracking}                     tracking                      To identify the account and user in the
 *                                                                     traking service.
 * @param {VideoUploaderAPI}             videoUploaderAPI              To set the authorization token and customerId.
 *
 * @memberof common
 */
const setToken = (
  $rootScope,
  analyticsExportAPI,
  appAPI,
  appSession,
  customerAvatarUploader,
  launchDarkly,
  heap,
  intercom,
  rightsExtension,
  socialAccountsWithStatusList,
  tracking,
  videoUploaderAPI,
) => {
  'ngInject';

  /**
   * Update the account, token and user information.
   */
  const update = () => {
    const { account, token, user } = appSession.getSession();
    appAPI.setAuthorizationToken(token);
    appAPI.setAuthorizationCustomerId(account.id);
    analyticsExportAPI.setAuthorizationToken(token);
    analyticsExportAPI.setAuthorizationCustomerId(account.id);
    customerAvatarUploader.setAuthorizationToken(token);
    customerAvatarUploader.setAuthorizationCustomerId(account.id);
    videoUploaderAPI.setAuthorizationToken(token);
    videoUploaderAPI.setAuthorizationCustomerId(account.id);

    // send Gtm UserAuthentication event
    const { dataLayer } = window;
    if (dataLayer) {
      dataLayer.push({
        event: 'userAuthenticated',
        platform: 'Earned',
        userInfo: {
          id: user.id,
          name: user.name,
          email: user.email,
          role: '',
        },
        accountInfo: {
          id: account.id,
        },
      });
    }

    launchDarkly.initLD(user.email);
    heap.identify(account, user);
    intercom.identify(account, user);
    tracking.setConfig(account, user);

    rightsExtension.setupAuthData(
      account.id,
      token,
      account.name,
      user.email,
    );

    socialAccountsWithStatusList.setCustomerId(account.id);
    socialAccountsWithStatusList.loadSocialAccounts();
  };

  const subscriptions = [
    appSession.onSessionLoaded(update),
    appSession.onSessionCreated(update),
  ];

  $rootScope.$on('$destroy', () => {
    subscriptions.forEach((unsubscribeFn) => unsubscribeFn());
  });
};

export default setToken;
