/* eslint-disable lines-between-class-members */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable sort-class-members/sort-class-members */
import template from './requestapprovals.html';
import './requestapprovals.scss';
import raTitle from '../../../assets/images/Request_Approvals_POC.svg';
import avatar from '../../../assets/images/olapicTest_demoAvatar.jpg';
/**
 * @ngdoc controller
 * @name RequestApprovalsContainer
 * @description
 * This container displays the RequestApprovals section.
 *
 * @memberof settings
 */
class RequestApprovalsContainer {
  /**
   * @param {$localStorage}                $localStorage                 To store facebook information on the browser.
   * @param {$location}                    $location                     To get the url params.
   * @param {$http}                        $http                         To make all the requests.
   * @param {$q}                           $q                            To handle promises.
   * @param {$timeout}                     $timeout                      To make a pooling load.
   * @param {AppAPI}                       appAPI                        To make the API requests.
   * @param {Object}                       appConfiguration              To get the API configuration.
   * @param {AppErrorHandler}              appErrorHandler               To display any error.
   * @param {AppSession}                   appSession                    To get the app session.
   * @param {AppUtils}                     appUtils                      To capitalize the social network text.
   * @param {UIMessages}                   uiMessages                    To display notification messages.
   * @param {$scope}                       $scope                        To apply the refresh changes.
   * @param {$window}                      $window                       To change the location href property.
   * @param {Object}     SETTINGS_MESSAGES  To display modal messages when connecting a Facebook account.
   */
  constructor(
    $localStorage,
    $location,
    $http,
    $q,
    $timeout,
    appAPI,
    appConfiguration,
    appErrorHandler,
    appSession,
    appUtils,
    uiMessages,
    $scope,
    $window,
    SETTINGS_MESSAGES,
  ) {
    'ngInject';

    this.clientID = '426166774080900';
    this.clientSE = 'fe265a2283803c7dd1f2aac148dd4fef';
    this.callback = 'https%3A%2F%2Fadmin.photorank.me%2Frequest-approvals';

    this.$localStorage = $localStorage;

    this.$location = $location;

    this.$http = $http;

    this.$q = $q;

    this.$scope = $scope;

    this.$timeout = $timeout;

    this.appAPI = appAPI;

    this.appConfiguration = appConfiguration;

    this.appErrorHandler = appErrorHandler;

    this.appSession = appSession;

    this.appUtils = appUtils;

    this.uiMessages = uiMessages;

    this.loadPromise = null;

    this.instagramAccountsList = [
      {
        id: 1,
        name: '@kel_socialnative',
        username: 'kel_socialnative',
        field: 'name',
        asc: true,
      },
      {
        id: 2,
        name: '@vinoddhomse',
        username: 'vinoddhomse',
        field: 'name',
        asc: true,
      },
      {
        id: 3,
        name: '@lemucreator',
        username: 'lemucreator',
        field: 'name',
        asc: true,
      },
    ];

    // eslint-disable-next-line prefer-destructuring
    this.selectedInstagramAccount = this.instagramAccountsList[0];

    this.messages = null;

    this.posts = [];

    this.conversation = [];

    this.titleImage = raTitle;

    this.selectedMedia = null;

    // eslint-disable-next-line max-len
    this.facebookAuthUrl = `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&redirect_uri=${this.callback}&scope=ads_management%2Cads_read%2Cpages_manage_ads%2Cpages_manage_engagement%2Cpages_manage_metadata%2Cpages_manage_posts%2Cpages_read_engagement%2Cpages_read_user_content%2Cpublic_profile%2Cinstagram_basic%2Cinstagram_manage_comments%2Cinstagram_content_publish%2Cinstagram_manage_insights%2Cbusiness_management&client_id=${this.clientID}`;

    this.$window = $window;

    this.modalMessages = SETTINGS_MESSAGES;

    this.avatar = avatar;

    this.accessToken = '';

    this.profileData = null;

    this.sessionKey = 'OLAPIC_REQUEST_APPROVALS';
  }

  onGotoFacebookAuth() {
    let promise = this.$q.resolve(true);

    promise = promise.then(() => this.uiMessages.confirmation(
      'Connect Facebook Account',
      this.modalMessages.facebookConfirmation,
      {
        confirmText: 'Start',
        destructive: false,
        type: 'large',
      },
    ));

    promise.then((confirm) => {
      if (confirm) {
        this.$window.location = this.facebookAuthUrl;
      }
    });
  }

  $onInit() {
    this._loadData();

    const { code } = this.$location.search();
    if (code) {
      // request access token to facebook
      this.getFacebookAccessToken(code).then((tokenResponse) => {
        this.accessToken = tokenResponse.access_token;

        this.getProfileData(code).then((profileResponse) => {
          this.profileData = profileResponse;

          this.getInstagramAccounts().then((accountsResponse) => {
            this.accounts = accountsResponse.data;
            this.storeFacebookData();
            this.$location.search('code', null);
          });
        });
      });
    } else {
      // load from localstorage
      const storedInformation = this.$localStorage[this.sessionKey] || {};
      this.accessToken = storedInformation.accessToken;
      this.profileData = storedInformation.profileData;
      this.accounts = storedInformation.accounts;
    }
  }

  storeFacebookData() {
    // Save the token and profile.
    const storedInformation = this.$localStorage[this.sessionKey] || {};
    this.$localStorage[this.sessionKey] = {
      ...storedInformation || {},
      accessToken: this.accessToken,
      profileData: this.profileData,
      accounts: this.accounts,
    };
  }

  getFacebookAccessToken(authCode) {
    // eslint-disable-next-line max-len
    const url = `https://graph.facebook.com/v16.0/oauth/access_token?client_id=${this.clientID}&redirect_uri=${this.callback}&client_secret=${this.clientSE}&code=${authCode}`;

    return this.$http.get(url).then((response) => response.data);
  }

  getProfileData() {
    // eslint-disable-next-line max-len
    const url = `https://graph.facebook.com/v16.0/me?fields=id,name,picture&access_token=${this.accessToken}`;
    return this.$http.get(url).then((response) => response.data);
  }

  getInstagramAccounts() {
    // eslint-disable-next-line max-len
    const url = `https://graph.facebook.com/v16.0/me/accounts?fields=instagram_business_account{id,username,name},id,access_token,name,tasks&access_token=${this.accessToken}`;
    return this.$http.get(url).then((response) => response.data);
  }

  /**
   * When a new account is selected, filter the DM list.
   *
   * @param {Object} account  The ....
   */
  onSelectInstagramAccount(account) {
    this.selectedInstagramAccount = account;
    this.filterConversation();
  }
  /**
   * Send the dm message.
   *
   */
  onSendDmMessage() {
    const msg = {
      user_id: this.messages[this.selectedInstagramAccount.username].id,
      reply: this.selectedMedia.message,
    };
    this.appAPI.createDmMessage(msg)
    .then(() => {
      this.selectedMedia.selected = false;
      this.selectedMedia = null;
      this.uiMessages.notification(
        'Request sent!',
        { type: 'info' },
      );
    });
  }

  checkHashtag() {
    if (this.messages) {
      const userMsgs = this.messages[this.selectedInstagramAccount.username];
      if (!userMsgs) {
        return false;
      }

      const approvedMsg = this.getApprovedMsg();
      return !!approvedMsg;
    }

    return false;
  }

  getApprovedMsg() {
    const userMsgs = this.messages[this.selectedInstagramAccount.username];
    // eslint-disable-next-line max-len
    return userMsgs.messages.find((msg) => this.posts.some((post) => (msg.text.includes(post.hashtag) && msg.text !== post.message)));
  }

  onSelectMedia(media) {
    if (media.rights_given === '0') {
      this.posts.forEach((post) => {
        if (post.id === media.id) {
          if (angular.isUndefined(media.selected)) {
            media.selected = false;
          }
          post.selected = !media.selected;
        } else {
          post.selected = false;
        }
      });
    }

    this.selectedMedia = this.posts.find((post) => post.selected);
  }

  async _getDmMessages() {
    try {
      this.messages = await this.appAPI.getDmMessages();
      this.filterConversation();
    } catch (error) {
      this.appErrorHandler.handle(
        error,
        'Sorry, there was an error while loading the DM messages. Please try again.',
      );
    }
  }

  filterConversation() {
    if (this.messages && this.messages[this.selectedInstagramAccount.username]) {
      this.conversation = this.messages[this.selectedInstagramAccount.username].messages;
    } else {
      this.conversation = [];
    }
  }

  async _getMedia() {
    try {
      this.posts = await this.appAPI.getDmMedia();
      if (this.selectedMedia) {
        this.posts.forEach((post) => {
          if (post.id === this.selectedMedia.id) {
            post.selected = true;
          }
        });
      }
      this.$scope.$digest();
    } catch (error) {
      this.appErrorHandler.handle(
        error,
        'Sorry, there was an error while loading the Posts. Please try again.',
      );
    }
  }

  async _loadData() {
    await this._getDmMessages();
    await this._getMedia();
    this._nextLoad();
  }

  _cancelNextLoad() {
    this.$timeout.cancel(this.loadPromise);
  }

  _nextLoad() {
    const mill = 10000; // Load the data every 10 seconds
    // Always make sure the last timeout is cleared before starting a new one
    this._cancelNextLoad();
    this.loadPromise = this.$timeout(() => {
      this._loadData();
    }, mill);
  }

  /**
   * Remove load timeout on destroy.
   */
  $onDestroy() {
    this._cancelNextLoad();
  }
}

/**
 * @ngdoc component
 * @name requestApprovalsContainer
 * @description
 * The RequestApprovals container.
 *
 * @memberof requestApprovals
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {RequestApprovalsContainer}
   */
  controller: RequestApprovalsContainer,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
};
