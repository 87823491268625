import EntitiesList from '../../common/abstracts/entitiesList';

/**
 * @ngdoc service
 * @name BlockedUsersList
 * @description
 * This service is used to search for blocked users.
 *
 * @memberof blockedUsersList
 */
class BlockedUsersList extends EntitiesList {
  /**
   * @param {$q}     $q      To reject error responses.
   * @param {AppAPI} appAPI  To make the API requests.
   */
  constructor(
    $q,
    appAPI,
  ) {
    super($q);

    /**
     * The local reference to the `appAPI` service.
     *
     * @type {AppAPI}
     */
    this.appAPI = appAPI;
  }
  /**
   * Call the API to make the request to block a user.
   *
   * @param {Object} userToBlock  The user object to block.
   *
   * @returns {Promise}
   */
  blockUsername(userToBlock) {
    return this._requestWithLoading(() => this.appAPI.blockUser(userToBlock));
  }
  /**
   * Call the API to make the blocked users list request.
   *
   * @returns {Promise}
   */
  getBlockedUsers() {
    return this._getEntities();
  }
  /**
   * Call the API to make the request to unblock a user.
   *
   * @param {object} userToUnblock  The user object to unblock.
   *
   * @returns {Promise}
   */
  unblockUser(userToUnblock) {
    return this._requestWithLoading(() => (
      this.appAPI.unblockUser(userToUnblock)
      .then(() => {
        const index = this.entities.findIndex((user) => user.id === userToUnblock.id);

        if (index > -1) {
          this.entities.splice(index, 1);
        }
      })
    ));
  }
  /**
   * Format an API response in order to the get the blocked users list.
   *
   * @param {Array} response  The response to format.
   *
   * @returns {Array}
   *
   * @access protected
   */
  _formatResponse(response) {
    return response.map((user) => ({
      ...user,
      text: `@${user.username}`,
      value: user.username,
    }));
  }
  /**
   * Call the API to make the request for the blocked users list.
   *
   * @returns {Promise}
   *
   * @access protected
   */
  _makeFirstRequest() {
    return this.appAPI.getBlockedUsers();
  }
}

/**
 * @ngdoc factory
 * @name blockedUsersList
 * @description
 * This object contains a method to create a new instance of the {@link BlockedUsersList}.
 *
 * @param {$q}     $q      To reject error responses.
 * @param {AppAPI} appAPI  To make the API requests.
 *
 * @returns {Function}
 *
 * @memberof blockedUsersList
 */
const blockedUsersList = (
  $q,
  appAPI,
) => {
  'ngInject';

  return {
    /**
     * Create a new BlockedUsersList Instance.
     *
     * @returns {BlockedList}
     */
    getNewInstance: () => new BlockedUsersList(
      $q,
      appAPI,
    ),
  };
};

export default blockedUsersList;
