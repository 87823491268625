import * as LDClient from 'launchdarkly-js-client-sdk';

/**
 * @ngdoc service
 * @name LaunchDarkly
 * @description
 * This service works as interface of LaunchDarkly to use feature flags.
 *
 * @memberof common
 */
class LaunchDarkly {
  /**
   * @param {Object}                 appConfiguration        To get the launchDarkly configuration.
   * @param {$q}                     $q                      To get the promise service.
   */
  constructor(
    appConfiguration,
    $q,
  ) {
    'ngInject';

    /**
     * The local reference to the `launchDarkly` config object.
     *
     * @type {Object}
     */
    this.launchDarklyConfig = appConfiguration.features.launchDarkly;
    /**
     * The local reference to the `$q` service.
     *
     * @type {$q}
     */
    this.$q = $q;
    /**
     * The local reference to the `launchDarkly` object.
     *
     * @type {Object}
     */
    this.ldclient = null;
  }
  /**
   * Returns the LDClient.
   *
   * @returns {Object}
   */
  getClient() {
    return this.ldclient;
  }
  /**
   * Returns a `$q` promise that will be resolved when `LaunchDarkly` becomes available.
   *
   * @param {string} userEmail  The user email.
   *
   * @returns {Promise}
   */
  initLD(userEmail) {
    const context = {
      kind: 'user',
      key: userEmail,
      email: userEmail,
    };
    const deferred = this.$q.defer();
    if (this.launchDarklyConfig.enabled) {
      const ldclient = LDClient.initialize(this.launchDarklyConfig.clientId, context);
      ldclient.on('ready', () => {
        deferred.resolve(ldclient);
        this.ldclient = ldclient;
      });
    } else {
      deferred.resolve(null);
    }
    return deferred.promise;
  }
}

export default LaunchDarkly;
