import template from './whitelistModal.html';
import './whitelistModal.scss';

/**
 * @ngdoc component
 * @name whitelistModal
 * @description
 * The modal for whitelist users bulk add component.
 *
 * @memberof whitelist
 */
export default {
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {Array}    labelSuggestions          The list of label suggestions to display.
   * @property {Array}    usernames                 The usernames to be added to the list.
   * @property {Array}    userToEdit                The user to be edited, if any.
   * @property {Function} onCancel                  Callback when closing and canceling any edition to the modal.
   * @property {Function} onSaveUserEdit            Callback for when we want to save changes to a user in the list.
   * @property {Function} onSearchLabelSuggestions  Callback for when we want to search label suggestions.
   * @property {Function} onSubmit                  Callback when saving the changes in modal.
   */
  bindings: {
    labelSuggestions: '<',
    usernames: '<',
    userToEdit: '<',
    onCancel: '&',
    onSaveUserEdit: '&',
    onSearchLabelSuggestions: '&',
    onSubmit: '&',
  },
};
