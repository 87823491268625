import template from './whitelistLabel.html';
import './whitelistLabel.scss';

/**
 * @ngdoc controller
 * @name WhitelistLabel
 * @description
 * This component renders the whitelist's label input.
 *
 * @memberof whitelist
 */
class WhitelistLabel {
  constructor() {
    /**
     * Flag to show or hide the Label input component.
     *
     * @type {boolean}
     */
    this.showLabelInput = false;
  }
  /**
   * Validate if the label exists in the list of suggestions.
   *
   * @returns {boolean}
   */
  existLabelInSuggestions() {
    return !this.labelSuggestions ||
      this.labelSuggestions.some((label) => label.toLowerCase() === this.labels.toLowerCase());
  }
  /**
   * Triggered the user press the enter or escape key from the input.
   *
   * @param {Object} $event  The key pressed event.
   */
  onKeydown($event) {
    const enterKeyCode = 13;
    const escapeKeyCode = 27;

    if ($event.keyCode === enterKeyCode || $event.keyCode === escapeKeyCode) {
      this.onToggleLabelVisibility();
    }
  }
  /**
   * Triggered when the user clicks a label suggestion item.
   *
   * @param {string} label  The label suggestion to set as label.
   */
  onSuggestionClick(label) {
    this.onLabelsSet({ labels: label });
    this.onToggleLabelVisibility();
  }
  /**
   * Toggles the Label input visibility.
   */
  onToggleLabelVisibility() {
    this.showLabelInput = !this.showLabelInput;
  }
}

/**
 * @ngdoc component
 * @name whitelistLabel
 * @description
 * The label input component.
 *
 * @memberof whitelist
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {WhitelistLabel}
   */
  controller: WhitelistLabel,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {string}   labels            Value for the label input.
   * @property {Array}    labelSuggestions  The list of label suggestions to display.
   * @property {Function} onLabelsSet       Callback when the user selects a label.
   */
  bindings: {
    labels: '<',
    labelSuggestions: '<',
    onLabelsSet: '&',
  },
};
