import template from './whitelist.html';
import './whitelist.scss';

import automaticRightsTitle from '../../../../assets/images/automaticRightsTitle.svg';
import emptyList from '../../../../assets/images/automaticRightsEmpty.svg';

/**
 * @ngdoc component
 * @name Whitelist
 * @description
 * This component renders the automatic rights list section.
 *
 * @memberof whitelist
 */
class Whitelist {
  constructor() {
    /**
     * Reference to the title image.
     *
     * @type {string}
     */
    this.automaticRightsTitle = automaticRightsTitle;
    /**
     * Reference to the emptyList image.
     *
     * @type {string}
     */
    this.emptyList = emptyList;
  }
  /**
   * Validates if the bulk edition is enabled or not.
   *
   * @returns {boolean}
   */
  isBulkEditEnabled() {
    return (
      this._hasEqualLabels() &&
      this.selectedSearchCriteria.name === 'label' &&
      this.search &&
      this.selectedWhitelistUsersCount > 1
    );
  }
  /**
   * Validates if the users selected to edit have the same label to display.
   *
   * @returns {boolean}
   */
  _hasEqualLabels() {
    const usersIds = Object.keys(this.selectedWhitelistUsers);
    if (usersIds.length) {
      const selectedUsers = this.whitelistUsers.filter((user) => usersIds.includes(`${user.id}`));
      return selectedUsers.every((selectedUser) => selectedUser.labelsDisplay === selectedUsers[0].labelsDisplay);
    }
    return false;
  }
}

/**
 * @ngdoc component
 * @name whitelist
 * @description
 * This component renders the automatic rights list section.
 *
 * @memberof whitelist
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {Whitelist}
   */
  controller: Whitelist,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {boolean}    isBulkModalVisible                 If the bulk modal is visible or not.
   * @property {boolean}    isSelectedAllWhitelistUsers        If the all the whitelist users are selected or not.
   * @property {Array}      labelSuggestions                   The list of label suggestions to display.
   * @property {boolean}    loading                            If a request for the whitelist is in progress.
   * @property {Pagination} pagination                         The pagination information.
   * @property {string}     search                             The text of the search box.
   * @property {Array}      searchCriteria                     The list of available search type.
   * @property {Object}     selectedSearchCriteria             The selected element from the search type options.
   * @property {Object}     selectedSortType                   The selected element from the sort by options.
   * @property {Object}     selectedStatus                     The selected element from the status by options.
   * @property {Object}     selectedWhitelistUsers             The map of selected whitelist users.
   * @property {number}     selectedWhitelistUsersCount        The count of selected whitelist users.
   * @property {Array}      sortTypes                          The list of sort types available.
   * @property {Object}     statusEmpty                        The status to show the no whitelist message on the table.
   * @property {Array}      statusTypes                        The list of statuses to filter.
   * @property {boolean}    wasTheSubmitSuccessful             Flag to indicate the result of the form's submit request.
   * @property {Array}      whitelistUsers                     The list of whitelist users to display.
   * @property {Function}   onBulkModalClose                   Callback for when the bulk modal is closed.
   * @property {Function}   onDeleteSelectedUsers              Callback for deleting selected whitelist users.
   * @property {Function}   onDeleteWhitelistUser              Callback for when we want to delete a whitelist user. It
   *                                                           receives the whitelist user to delete.
   * @property {Function}   onEditSelectedUsers                Callback for editing selected whitelist users.
   * @property {Function}   onPaginationNextClick              Callback for when the pagination next button is clicked.
   * @property {Function}   onPaginationPreviousClick          Callback for when the pagination previous
   *                                                           button is clicked.
   * @property {Function}   onSaveWhitelistUsers               Callback for when we want to create/update a list of
   *                                                           whitelist users. It receives the whitelist users to
   *                                                           create/update and selected source.
   * @property {Function}   onSearchLabelSuggestions           Callback for when we want to search label suggestions.
   * @property {Function}   onSearchWhitelistUsers             Callback for when we want to search whitelist user by
   *                                                           username. It receives the search to make.
   * @property {Function}   onSortingChange                    Callback when selecting/changing sort by options.
   * @property {Function}   onStatusChange                     Callback when selecting/changing status by options.
   * @property {Function}   onWhitelistUserSelectedChange      Callback when the selection of a user is changed. It
   *                                                           receives the user and if it was selected or unselected.
   * @property {Function}   onWhitelistAllUsersSelectedChange  Callback when the selection of a user is changed. It
   *                                                           receives the user, if it was selected or unselected and
   *                                                           if we are editing or not.
   */
  bindings: {
    isBulkModalVisible: '<',
    isSelectedAllWhitelistUsers: '<',
    labelSuggestions: '<',
    loading: '<',
    pagination: '<',
    search: '<',
    searchCriteria: '<',
    selectedSearchCriteria: '<',
    selectedSortType: '<',
    selectedStatus: '<',
    selectedWhitelistUsers: '<',
    selectedWhitelistUsersCount: '<',
    sortTypes: '<',
    statusEmpty: '<',
    statusTypes: '<',
    wasTheSubmitSuccessful: '<',
    whitelistUsers: '<',
    onBulkModalClose: '&',
    onDeleteSelectedUsers: '&',
    onDeleteWhitelistUser: '&',
    onEditSelectedUsers: '&',
    onPaginationNextClick: '&',
    onPaginationPreviousClick: '&',
    onSaveWhitelistUsers: '&',
    onSearchLabelSuggestions: '&',
    onSearchWhitelistUsers: '&',
    onSortingChange: '&',
    onStatusChange: '&',
    onWhitelistUserSelectedChange: '&',
    onWhitelistAllUsersSelectedChange: '&',
  },
};
