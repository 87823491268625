import EntitiesList from '../../common/abstracts/entitiesList';

/**
 * @ngdoc service
 * @name BlockedHashtagsList
 * @description
 * This service is used to search for blocked hashtags.
 *
 * @memberof blockedHashtagsList
 */
class BlockedHashtagsList extends EntitiesList {
  /**
   * @param {$q}     $q      To reject error responses.
   * @param {AppAPI} appAPI  To make the API requests.
   */
  constructor(
    $q,
    appAPI,
  ) {
    super($q);

    /**
     * The local reference to the `appAPI` service.
     *
     * @type {AppAPI}
     */
    this.appAPI = appAPI;
  }
  /**
   * Call the API to make the request to block a hashtags list.
   *
   * @param {Array} hashtagsList  The array of hashtags to block.
   *
   * @returns {Promise}
   */
  blockHashtagsList(hashtagsList) {
    return this._requestWithLoading(() => this.appAPI.blockHashtagsList(hashtagsList));
  }
  /**
   * Call the API to make the blocked hashtags list request.
   *
   * @returns {Promise}
   */
  getBlockedHashtags() {
    return this._getEntities();
  }
  /**
   * Call the API to make the request to unblock a hashtag.
   *
   * @param {object} hashtagToUnblock  The hashtag object to block.
   *
   * @returns {Promise}
   */
  unblockHashtag(hashtagToUnblock) {
    return this._requestWithLoading(() => (
      this.appAPI.unblockHashtag(hashtagToUnblock)
      .then(() => {
        const index = this.entities.indexOf(hashtagToUnblock);

        if (index > -1) {
          this.entities.splice(index, 1);
        }
      })
    ));
  }
  /**
   * Format an API response in order to the get the blocked hashtags list.
   *
   * @param {Array} response  The response to format.
   *
   * @returns {Array}
   *
   * @access protected
   */
  _formatResponse(response) {
    return response.map((hashtag) => ({
      id: hashtag,
      hashtag,
      source: 'all',
      text: `#${hashtag}`,
      value: hashtag,
    }));
  }
  /**
   * Call the API to make the request for the blocked hashtags list.
   *
   * @returns {Promise}
   *
   * @access protected
   */
  _makeFirstRequest() {
    return this.appAPI.getBlockedHashtags();
  }
}

/**
 * @ngdoc factory
 * @name blockedHashtagsList
 * @description
 * This object contains a method to create a new instance of the {@link BlockedHashtagsList}.
 *
 * @param {$q}     $q      To reject error responses.
 * @param {AppAPI} appAPI  To make the API requests.
 *
 * @returns {Function}
 *
 * @memberof blockedHashtagsList
 */
const blockedHashtagsList = (
  $q,
  appAPI,
) => {
  'ngInject';

  return {
    /**
     * Create a new BlockedHashtagsList Instance.
     *
     * @returns {BlockedHashtagsList}
     */
    getNewInstance: () => new BlockedHashtagsList(
      $q,
      appAPI,
    ),
  };
};

export default blockedHashtagsList;
