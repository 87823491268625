import EntitiesList from '../../common/abstracts/entitiesList';

/**
 * @ngdoc class
 * @name CollectionsList
 * @description
 * This service is used to get the customer collections.
 *
 * @memberof collections
 */
class CollectionsList extends EntitiesList {
  /**
   * @param {$filter} $filter                     To filter the collections list.
   * @param {$q}      $q                          To reject error responses.
   * @param {AppAPI}  appAPI                      To make the API requests.
   * @param {Object}  appConfiguration            To get the hashtag limit.
   * @param {Extend}  extend                      To merge the contents of two collections.
   * @param {Object}  COLLECTIONS_BASE_TYPES      To get the base types configuration.
   * @param {Object}  COLLECTIONS_PROFILE         To get the profile collection.
   * @param {Object}  COLLECTIONS_RULE_OPERATORS  To get the rule operators configuration.
   * @param {Object}  COLLECTIONS_RULE_TYPES      To get the rule types configuration.
   */
  constructor(
    $filter,
    $q,
    appAPI,
    appConfiguration,
    extend,
    COLLECTIONS_BASE_TYPES,
    COLLECTIONS_PROFILE,
    COLLECTIONS_RULE_OPERATORS,
    COLLECTIONS_RULE_TYPES,
  ) {
    super($q);

    /**
     * The local reference to the `$filter` service.
     *
     * @type {$filter}
     */
    this.$filter = $filter;
    /**
     * The local reference to the `appAPI` service.
     *
     * @type {AppAPI}
     */
    this.appAPI = appAPI;
    /**
     * The local reference to the `extend` service.
     *
     * @type {Extend}
     */
    this.extend = extend;
    /**
     * The local reference to the `COLLECTIONS_BASE_TYPES` constant.
     *
     * @type {Object}
     */
    this.COLLECTIONS_BASE_TYPES = COLLECTIONS_BASE_TYPES;
    /**
     * The local reference to the `COLLECTIONS_PROFILE` constant.
     *
     * @type {Object}
     */
    this.COLLECTIONS_PROFILE = COLLECTIONS_PROFILE;
    /**
     * The local reference to the `COLLECTIONS_RULE_OPERATORS` constant.
     *
     * @type {Object}
     */
    this.COLLECTIONS_RULE_OPERATORS = COLLECTIONS_RULE_OPERATORS;
    /**
     * The local reference to the `COLLECTIONS_RULE_TYPES` constant.
     *
     * @type {Object}
     */
    this.COLLECTIONS_RULE_TYPES = COLLECTIONS_RULE_TYPES;
    /**
     * The local reference to the `hashtagLimit` constant.
     *
     * @type {number}
     */
    this.hashtagLimit = appConfiguration.collections.hashtagLimit;
    /**
     * The collection list.
     *
     * @type {Array}
     */
    this.collections = [];
    /**
     * The amount of collection per page the list will load and show.
     *
     * @type {Object}
     */
    this.pageSize = 20;
    /**
     * The pagination information.
     *
     * @type {Object}
     */
    this.pagination = {
      from: 0,
      to: 0,
      total: 0,
      next: false,
      prev: false,
    };
    /**
     * The collection template to use when preparing a collection for edit.
     *
     * @type {Object}
     */
    this.collectionTemplate = {
      id: 0,
      name: '',
      phrase: '',
      starts_at: null,
      ends_at: null,
      mediaFrom: '',
      base: {
        type: null,
        data: {
          value: '',
          mention: null,
        },
      },
      mediaTypes: {
        image: true,
        video: false,
      },
      rules: [],
      streams: [],
      sendToModeration: false,
      includes: [],
      excludes: [],
    };
    /**
     * The collection template to use when preparing a collection to save.
     *
     * @type {Object}
     */
    this.collectionSaveTemplate = {
      name: '',
      phrase: 'Collect!',
      starts_at: null,
      ends_at: null,
      base: {
        type: null,
        data: {},
        social_network: '',
      },
      filter: {
        id: 0,
        operators: [],
        streams: [],
      },
      actions: [],
    };
    /**
     * The text to search collections for.
     *
     * @type {string}
     */
    this.search = '';
    /**
     * The sort to order the collections with.
     *
     * @type {Object}
     */
    this.selectedSort = {};
    /**
     * The status to filter the collections with.
     *
     * @type {Object}
     */
    this.selectedStatusFilter = {};
    /**
     * The collection that is being created or edited.
     *
     * @type {Object}
     */
    this.collection = {};
    // Set the pagination type as `page`.
    this._setPaginationAsPage();
  }
  /**
   * Call the API to change the status of a collection.
   *
   * @param {number} collectionId  The id of the collection to change the status.
   * @param {string} status        The status to set to the collection.
   *
   * @returns {Promise}
   */
  changeCollectionStatus(collectionId, status) {
    return this._requestWithLoading(() => (
      this.appAPI.changeCollectionStatus(collectionId, status)
      .then((editedCollection) => {
        this._mergeEditedCollection(editedCollection);
      })
    ));
  }
  /**
   * Call the API to to create a new stream from collections.
   *
   * @param {string} apiKey      The customer apiKey.
   * @param {string} streamName  The stream name to be created.
   *
   * @returns {Promise}
   */
  createStream(apiKey, streamName) {
    return this._requestWithLoading(() => (
      this.appAPI.createStream(apiKey, streamName)
    ));
  }
  /**
   * Delete a collection.
   *
   * @param {number} collectionId  The id of the collection to delete.
   *
   * @returns {Promise}
   */
  deleteCollection(collectionId) {
    return this._requestWithLoading(() => (
      this.appAPI.deleteCollection(collectionId)
      .then(() => {
        const index = this.entities.findIndex((collection) => collection.filter.id === collectionId);

        if (index > -1) {
          this.entities.splice(index, 1);

          // If the pagination from is bigger than the entities list, move the page one back.
          const from = this.pagination.from > this.entities.length ?
            this.pagination.from - this.pageSize :
            this.pagination.from;

          this.refreshCollectionsList(from);
        }
      })
    ));
  }
  /**
   * Delete a profile collection.
   *
   * @param {number} collectionId  The id of the profile collection to delete.
   *
   * @returns {Promise}
   */
  deleteProfileCollection(collectionId) {
    return this._requestWithLoading(() => (
      this.appAPI.deleteCollection(collectionId)
      .then(() => {
        const index = this.entities.findIndex((entity) => entity.filter.id === collectionId);

        if (index > -1) {
          // Remove the deleted profile collection and add the default one.
          this.entities.splice(index, 1);
          this.entities.push(angular.copy(this.COLLECTIONS_PROFILE));
          this.refreshCollectionsList(this.pagination.from);
        }
      })
    ));
  }
  /**
   * Call the API to make the collections request.
   *
   * @returns {Promise}
   */
  getCollections() {
    this._clearBeforeRequest();

    return this._getEntities()
    .then(() => {
      this.refreshCollectionsList();
    });
  }
  /**
   * Get the next page of collections.
   *
   * @throws Error.
   */
  getNextPage() {
    if (!this.pagination.next) {
      throw new Error('Unable to fetch the next page');
    }

    const from = this.pagination.from + this.pageSize;
    this.refreshCollectionsList(from);
  }
  /**
   * Get the previous page of collections.
   *
   * @throws Error.
   */
  getPreviousPage() {
    if (!this.pagination.prev) {
      throw new Error('Unable to fetch the previous page');
    }

    const from = this.pagination.from - this.pageSize;
    this.refreshCollectionsList(from);
  }
  /**
   * Parse a collection and prepare it to be edited in the form.
   *
   * @param {Object}  collection  The collection to prepare.
   * @param {boolean} isTemplate  If the collection will be used as a template for a new one.
   */
  prepareCollectionForEdit(collection, isTemplate) {
    this.collection = angular.copy(this.collectionTemplate);

    if (collection) {
      if (!isTemplate) {
        this.collection.id = collection.filter.id;
        this.collection.name = collection.name;
      } else {
        this.collection.name = `COPY - ${collection.name}`;
      }
      this.collection.phrase = collection.phrase;

      const baseType = this.COLLECTIONS_BASE_TYPES[collection.base.type];
      this.collection.base.type = baseType;
      this.collection.base.data = collection.base.data;
      this.collection.base.data.value = baseType.symbol + collection.base.data[baseType.data];

      // Set the mention id
      if (this.collection.base.type.id === 'mention') {
        this.collection.base.data.mention = {
          id: this.collection.base.data.value,
          name: this.collection.base.data.value,
        };
      }

      // Set social network
      this.collection.mediaFrom = collection.base.social_network;

      // Adjust the base value to the maxlength allowed
      this.collection.base.data.value = this.collection.base.data.value.substring(0, baseType.maxlength);

      // Add start and end dates
      this.collection.starts_at = collection.starts_at;
      this.collection.ends_at = collection.ends_at;

      // Process rules
      this.collection.rules = collection.filter.operators
      .filter((rule) => {
        if (rule.type === 'media_type') {
          Object.keys(this.collection.mediaTypes).forEach((type) => {
            this.collection.mediaTypes[type] = rule.data.types.includes(type);
          });
          return false;
        }

        const ruleType = this.COLLECTIONS_RULE_TYPES[rule.type];
        const ruleValue = ruleType.symbol + rule.data[ruleType.data];

        return baseType.id !== rule.type || this.collection.base.data.value !== ruleValue;
      })
      .map((rule) => {
        const ruleOperator = this.COLLECTIONS_RULE_OPERATORS[rule.name];
        const ruleType = this.COLLECTIONS_RULE_TYPES[rule.type];
        let ruleValue = ruleType.symbol + rule.data[ruleType.data];

        // Adjust the value to the maxlength allowed
        ruleValue = ruleValue.substring(0, ruleType.maxlength);

        return {
          operator: ruleOperator,
          type: ruleType,
          value: ruleValue,
        };
      });

      // Process include hashtag filters (without base hashtag)
      this.collection.includes = collection.filter.operators
      .filter((rule) => {
        if (rule.type !== 'hashtag' || rule.name !== 'with') {
          return false;
        }
        const ruleType = this.COLLECTIONS_RULE_TYPES[rule.type];
        const ruleValue = ruleType.symbol + rule.data[ruleType.data];
        return baseType.id !== rule.type || this.collection.base.data.value !== ruleValue;
      })
      .map((rule) => {
        const ruleType = this.COLLECTIONS_RULE_TYPES[rule.type];
        let ruleValue = ruleType.symbol + rule.data[ruleType.data];

        // Adjust the value to the maxlength allowed
        ruleValue = ruleValue.substring(0, ruleType.maxlength);
        return ruleValue;
      });

      // Process exclude hashtag filters (without base hashtag)
      this.collection.excludes = collection.filter.operators
      .filter((rule) => {
        if (rule.type !== 'hashtag' || rule.name !== 'without') {
          return false;
        }
        const ruleType = this.COLLECTIONS_RULE_TYPES[rule.type];
        const ruleValue = ruleType.symbol + rule.data[ruleType.data];
        return baseType.id !== rule.type || this.collection.base.data.value !== ruleValue;
      })
      .map((rule) => {
        const ruleType = this.COLLECTIONS_RULE_TYPES[rule.type];
        let ruleValue = ruleType.symbol + rule.data[ruleType.data];

        // Adjust the value to the maxlength allowed
        ruleValue = ruleValue.substring(0, ruleType.maxlength);
        return ruleValue;
      });

      // Process streams
      this.collection.streams = collection.filter.streams
      .filter((stream) => stream.id)
      .map((stream) => ({
        id: stream.id,
        name: stream.name,
        backName: stream.name,
      }));

      // Process destination - If it doesn't have any action set the sendToModeration in true
      this.collection.sendToModeration = !collection.actions || !collection.actions.length;
    }
  }
  /**
   * Generate the collection list, by first filtering using the search text.
   * Then, we order the list with the selected sort.
   * Then, we generate the pagination object.
   * Finally we filter the list according to the page size and the starting point.
   *
   * @param {number} from  The starting point to set the pagination to.
   */
  refreshCollectionsList(from = 1) {
    // First filter the collections entities with the search text not using strict comparison.
    let list = this.$filter('filter')(
      this.entities,
      { q: this.search },
    );

    // Then filter the list with the status filter using strict comparison.
    list = this.$filter('filter')(
      list,
      { status: this.selectedStatusFilter.status },
      true,
    );

    this.collections = this.$filter('orderBy')(
      list,
      this.selectedSort.field,
      !this.selectedSort.asc,
    );

    const to = Math.min((from + this.pageSize) - 1, this.collections.length);
    this.pagination = {
      from,
      to,
      total: this.collections.length,
      next: to !== this.collections.length,
      prev: from !== 1,
    };

    this.collections = this.$filter('limitTo')(
      this.collections,
      this.pageSize,
      from - 1,
    );
  }
  /**
   * Call the API to create or edit a collection.
   *
   * @param {Object} collection  The collection to create or edit.
   *
   * @returns {Promise}
   */
  saveCollection(collection) {
    return this._requestWithLoading(() => {
      const collectionToSave = this._prepareCollectionToSave(collection);
      let result;

      if (collectionToSave.filter.id) {
        result = this.appAPI.editCollection(collectionToSave)
        .then((editedCollection) => {
          // Update collection returned by backend
          this._mergeEditedCollection({
            ...editedCollection,
            sendToModeration: collection.sendToModeration,
            mediaTypes: collection.mediaTypes,
            streams: collection.streams,
            includes: collection.includes,
            excludes: collection.excludes,
          });
        });
      } else {
        result = this.appAPI.createCollection(collectionToSave)
        .then((newCollection) => {
          newCollection.q = `${newCollection.name} ${newCollection.phrase}`;
          // Update collection returned by backend
          newCollection.sendToModeration = collection.sendToModeration;
          newCollection.mediaTypes = collection.mediaTypes;
          newCollection.streams = collection.streams;
          newCollection.includes = collection.includes;
          newCollection.excludes = collection.excludes;
          this._processBaseDataValue(newCollection);
          this.entities.push(newCollection);
          this.refreshCollectionsList(this.pagination.from);

          return newCollection;
        });
      }

      return result;
    });
  }
  /**
   * Call the API to save a profile collection.
   *
   * @param {Object} collection  The profile collection to save.
   *
   * @returns {Promise}
   */
  saveProfileCollection(collection) {
    return this._requestWithLoading(() => (
      this.appAPI.createCollection(collection)
      .then((createdCollection) => {
        const collectionSaved = this.entities.find((entity) => entity.filter.id === collection.filter.id);

        if (collectionSaved) {
          this.extend(true, collectionSaved, createdCollection);
          this.refreshCollectionsList(this.pagination.from);
        }
      })
    ));
  }
  /**
   * Validate if the name of a collection already exist in the list of entities.
   *
   * @param {Object} collection  The collection to validate.
   *
   * @returns {boolean}
   */
  validateCollectionName(collection) {
    const existCollectionSameName = this.entities.some((entity) => (
      collection.id !== entity.filter.id &&
      collection.name.toLowerCase() === entity.name.toLowerCase()
    ));

    return !existCollectionSameName;
  }
  /**
   * Validate if we are within the limit of hashtag collections allowed to be created.
   *
   * @param {Object} collection  The collection to validate.
   *
   * @returns {boolean}
   */
  validateHashtagLimit(collection) {
    let withinLimit = true;
    const hashtagType = 'hashtag';

    if (
      !collection.id &&
      collection.base.type &&
      collection.base.type.id === hashtagType
    ) {
      const totalOfHashtags = this.entities.filter((entity) => entity.base.type === hashtagType).length;
      withinLimit = this.hashtagLimit > totalOfHashtags;
    }

    return withinLimit;
  }
  /**
   * Clear the entities list so the UI get cleared before performing an API request.
   *
   * @access protected
   */
  _clearBeforeRequest() {
    this.collections = [];
  }
  /**
   * Format an API response in order to the get the collections list.
   *
   * @param {Array} response  The response to format.
   *
   * @returns {Array}
   *
   * @access protected
   */
  _formatResponse(response) {
    let existProfileCollection = false;
    const collections = response.map((collection) => {
      if (collection.base.type !== 'profile') {
        collection.phrase = this.collection.phrase;
        collection.sendToModeration = !collection.actions || !collection.actions.length;
        this._processBaseDataValue(collection);
      }
      collection.q = `${collection.name} ${collection.phrase}`;

      existProfileCollection = existProfileCollection || collection.base.type === 'profile';

      // Process media type
      collection.filter.operators.forEach((rule) => {
        if (rule.type === 'media_type') {
          collection.mediaTypes = {
            image: rule.data.types.includes('image'),
            video: rule.data.types.includes('video'),
          };
        }
      });

      // Process streams
      collection.streams = collection.filter.streams
      .filter((stream) => stream.id)
      .map((stream) => ({
        id: stream.id,
        name: stream.name,
        backName: stream.name,
      }));

      // Process hashtags
      collection.includes = this._processCollectionHashtags(collection, true);
      collection.excludes = this._processCollectionHashtags(collection, false);

      return collection;
    });

    // If the profile collection does not exist, add the default one.
    if (!existProfileCollection) {
      collections.push(angular.copy(this.COLLECTIONS_PROFILE));
    }

    return collections;
  }
  /**
   * Call the API to make the request for the collections list.
   *
   * @returns {Promise}
   *
   * @access protected
   */
  _makeFirstRequest() {
    return this.appAPI.getCollections();
  }
  /**
   * Merge an edited collection into the list of entities.
   *
   * @param {Object} editedCollection  The collection to merge.
   *
   * @access protected
   */
  _mergeEditedCollection(editedCollection) {
    const collectionToMerge = this.entities.find((collection) => collection.filter.id === editedCollection.filter.id);
    if (collectionToMerge) {
      // Delete the collection arrays.
      delete collectionToMerge.actions;
      delete collectionToMerge.filter.operators;
      delete collectionToMerge.filter.streams;
      delete collectionToMerge.streams;
      delete collectionToMerge.includes;
      delete collectionToMerge.excludes;
      this.extend(true, collectionToMerge, editedCollection);
      this.refreshCollectionsList(this.pagination.from);
    }
  }
  /**
   * Parse a collection and prepare it to be saved.
   *
   * @param {Object} collection  The collection to prepare.
   *
   * @returns {Object}
   *
   * @access protected
   */
  _prepareCollectionToSave(collection) {
    const collectionToSave = angular.copy(this.collectionSaveTemplate);
    const baseType = collection.base.type;

    collectionToSave.name = collection.name;
    collectionToSave.base.type = baseType.id;
    collectionToSave.filter.id = collection.id;
    collectionToSave.sendToModeration = collection.save;

    // Set the base data.
    collectionToSave.base.data[baseType.data] = collection.base.data[baseType.data];
    if (baseType.id === 'handler') {
      collectionToSave.base.data.social_networks = ['twitter'];
    }
    if (baseType.id === 'mention') {
      collectionToSave.base.data[baseType.data] = collection.base.data.mention.id;
      collectionToSave.base.data.social_networks = ['instagram'];
    }
    collectionToSave.base.social_network = collection.mediaFrom;
    collectionToSave.starts_at = collection.starts_at;
    collectionToSave.ends_at = collection.ends_at;

    // Set the base as a filter.
    const baseFilter = {
      name: 'with',
      type: baseType.id,
      data: {},
    };
    baseFilter.data[baseType.data] = collectionToSave.base.data[baseType.data];
    collectionToSave.filter.operators.push(baseFilter);

    // Set the media type as a filter.
    const types = Object.keys(collection.mediaTypes).filter((key) => collection.mediaTypes[key]);
    collectionToSave.filter.operators.push({
      name: 'with',
      type: 'media',
      data: { types },
    });

    // Process rules.
    collectionToSave.filter.operators = collectionToSave.filter.operators.concat((
      collection.rules
      .filter((rule) => rule.operator && rule.type && rule.value && !rule.error)
      .map((rule) => {
        const filter = {
          name: rule.operator.id,
          type: rule.type.id,
          data: {},
        };
        filter.data[rule.type.data] = rule.value;

        return filter;
      })
    ));

    // Process streams.
    collectionToSave.filter.streams = collection.streams
    .filter((stream) => stream.id)
    .map((stream) => stream.id);

    // Process destination.
    if (!collection.sendToModeration) {
      collectionToSave.actions.push({
        name: 'skipSteps',
        inputs: { steps: ['pre_moderation'] },
      });
    } else {
      collectionToSave.actions = [];
    }

    // Remove the base when editing a collection.
    if (collection.id) {
      delete collectionToSave.base;
    }

    return collectionToSave;
  }
  /**
   * Parse the base value (hashtag or username)
   * and set it with the symbol on a single 'value' property.
   *
   * @param {Object}  collection  The collection to process.
   *
   * @access protected
   */
  _processBaseDataValue(collection) {
    const baseType = this.COLLECTIONS_BASE_TYPES[collection.base.type];
    collection.base.symbol = baseType.symbol;
    collection.base.data.value = `${baseType.symbol}${collection.base.data[baseType.data]}`;
  }
  /**
   * Parse the collection filters and returns a list of hashtags.
   *
   * @param {Object}  collection  The collection to prepare.
   * @param {boolean} isWithRule  If the rule type is 'with' or 'without'.
   * @returns {Object}
   *
   * @access protected
   */
  _processCollectionHashtags(collection, isWithRule) {
    const ruleName = isWithRule ? 'with' : 'without';
    return collection.filter.operators
    .filter((rule) => (rule.type === 'hashtag' && rule.name === ruleName))
    .map((rule) => {
      const ruleType = this.COLLECTIONS_RULE_TYPES[rule.type];
      let ruleValue = ruleType.symbol + rule.data[ruleType.data];
      ruleValue = ruleValue.substring(0, ruleType.maxlength);
      return ruleValue;
    });
  }
}

/**
 * @ngdoc factory
 * @name collectionsList
 * @description
 * This object contains a method to create a new instance of the {@link CollectionsList}.
 *
 * @param {$filter} $filter                     To filter the collections list.
 * @param {$q}      $q                          To reject error responses.
 * @param {AppAPI}  appAPI                      To make the API requests.
 * @param {Object}  appConfiguration            To get the hashtag limit.
 * @param {Extend}  extend                      To merge the contents of two collections.
 * @param {Object}  COLLECTIONS_BASE_TYPES      To get the base types configuration.
 * @param {Object}  COLLECTIONS_PROFILE         To get the profile collection.
 * @param {Object}  COLLECTIONS_RULE_OPERATORS  To get the rule operators configuration.
 * @param {Object}  COLLECTIONS_RULE_TYPES      To get the rule types configuration.
 *
 * @returns {Function}
 *
 * @memberof collections
 */
const collectionsList = (
  $filter,
  $q,
  appAPI,
  appConfiguration,
  extend,
  COLLECTIONS_BASE_TYPES,
  COLLECTIONS_PROFILE,
  COLLECTIONS_RULE_OPERATORS,
  COLLECTIONS_RULE_TYPES,
) => {
  'ngInject';

  return {
    /**
     * Create a new CollectionsList Instance.
     *
     * @returns {CollectionsList}
     */
    getNewInstance: () => new CollectionsList(
      $filter,
      $q,
      appAPI,
      appConfiguration,
      extend,
      COLLECTIONS_BASE_TYPES,
      COLLECTIONS_PROFILE,
      COLLECTIONS_RULE_OPERATORS,
      COLLECTIONS_RULE_TYPES,
    ),
  };
};

export default collectionsList;
