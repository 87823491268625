import cropIcon from '../../../assets/images/volumeMute-icon.svg';

/**
 * @ngdoc constant
 * @name LIBRARY_MUTE_STATUS
 * @description
 * The object to map the media Mute Status that indicates the diferent icons and tooltips for
 * the different states of the video media.
 *
 * @type {MuteStatusMap}
 *
 * @memberof library
 */
const libraryMuteStatus = {
  PENDING: {
    toolTip: 'Audio removal is being processed. Please, refresh the page to see the change.',
    image: cropIcon,
    errorIcon: false,
  },
  PROCESSED: {
    toolTip: 'This media is an edited version of the original.',
    image: cropIcon,
    errorIcon: false,
  },
  FAILED: {
    toolTip: 'There was an error while removing the audio. Please, try again.',
    image: cropIcon,
    errorIcon: true,
  },
};

export default libraryMuteStatus;
