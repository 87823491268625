import statuses from 'statuses';
import EntitiesList from '../abstracts/entitiesList';

/**
 * @ngdoc service
 * @name SocialAccountsWithStatusList
 * @description
 * This service is used to get the customer social accounts with their statuses.
 *
 * @memberof common
 */
class SocialAccountsWithStatusList extends EntitiesList {
  /**
   * @param {$localStorage}   $localStorage     To store the social accounts information on the browser.
   * @param {$q}              $q                To reject error responses.
   * @param {$timeout}        $timeout          To refresh the social accounts.
   * @param {AppAPI}          appAPI            To make the API requests.
   * @param {Object}          appConfiguration  To get the social accounts TTL.
   * @param {AppErrorHandler} appErrorHandler   To display any error.
   * @param {AppSession}      appSession        To get the account settings from session.
   * @param {AppUtils}        appUtils          To create delayed promises.
   * @param {Moment}          moment            To perform date manipulation.
   */
  constructor(
    $localStorage,
    $q,
    $timeout,
    appAPI,
    appConfiguration,
    appErrorHandler,
    appSession,
    appUtils,
    moment,
  ) {
    'ngInject';

    super($q);

    /**
     * The local reference to the `$localStorage` service.
     *
     * @type {$localStorage}
     */
    this.$localStorage = $localStorage;
    /**
     * The local reference to the `$timeout` service.
     *
     * @type {$timeout}
     */
    this.$timeout = $timeout;
    /**
     * The local reference to the `appAPI` service.
     *
     * @type {AppAPI}
     */
    this.appAPI = appAPI;
    /**
     * The local reference to the `appErrorHandler` service.
     *
     * @type {AppErrorHandler}
     */
    this.appErrorHandler = appErrorHandler;
    /**
     * The local reference to the `appSession` service.
     *
     * @type {AppSession}
     */
    this.appSession = appSession;
    /**
     * The local reference to the `appUtils` service.
     *
     * @type {AppUtils}
     */
    this.appUtils = appUtils;
    /**
     * The local reference to the `moment` service.
     *
     * @type {Moment}
     */
    this.moment = moment;
    /**
     * The social accounts TTL.
     *
     * @type {number}
     */
    this.socialAccountsTTL = appConfiguration.socialAccountsTTL;
    /**
     * The listeners to inform about social accounts changes.
     *
     * @type {Object}
     */
    this.listeners = {};
    /**
     * The key for the storage record that will save the social accounts information.
     *
     * @type {string}
     */
    this.sessionKey = 'OLAPIC_SOCIAL_ACCOUNTS_LIST';
    /**
     * The customer id to save as storage key.
     *
     * @type {number}
     */
    this.customerId = 0;
    /**
     * The social mentions accounts associated with this customer.
     *
     * @type {Object}
     */
    this.socialMentionsAccounts = [];
    /**
     * The facebook pages associated with this customer.
     *
     * @type {Object}
     */
    this.facebookPages = [];
    /**
     * The list of functionalities to check for error state.
     *
     * @type {Array}
     */
    this.functionalitiesToCheck = [
      'mention_collection',
      'rights_request',
      'media_tag_collection',
    ];
    /**
     * Map of the error state.
     *
     * @type {Object}
     */
    this.errorState = {
      accounts: false,
      loginFailed: false,
    };
    /**
     * The reference current request in progress.
     *
     * @type {Promise}
     * @access protected
     */
    this._currentRequest = null;
    /**
     * The reference current $timeout in progress.
     *
     * @type {Object}
     * @access protected
     */
    this._currentTimeout = null;

    // Set the pagination type as `page`.
    this._setPaginationAsPage();
  }
  /**
   * If we are currently doing a request (loading is true), try to cancel the current request.
   * If the cancel method return a success value (true), we must respond with a delayed promise
   * so the loading flag gets changed.
   *
   * @returns {Promise}
   */
  cancelCurrentRequest() {
    return this.loading && this.appAPI.cancelRequest(this._currentRequest) ?
      // We are responding with a delayed promise of 0 because we need the cancelRequest to complete.
      this.appUtils.delayedPromise(0) :
      this.$q.resolve();
  }
  /**
   * Starts the social accounts loading process.
   * If there are stored social accounts, set the social mentions accounts and
   * and set a timeout to refresh the social acccounts in the given time to expire.
   * If not, call the API to make the social accounts with status request.
   */
  loadSocialAccounts() {
    const storedInfo = this._getStorageInformation();

    if (storedInfo && storedInfo.list) {
      const [firstItem] = storedInfo.list;
      this.socialMentionsAccounts = this._formatFunctionalitiesStatusForChildAccounts(firstItem.child_accounts);
      this.facebookPages = firstItem.facebook_pages;
      this._checkErrorState();

      // Refresh the social acccounts in the given time to expire.
      this._currentTimeout = this.$timeout(
        () => this._getSocialAccountsWithStatus(),
        storedInfo.timeToExpire || new Date().getTime() + this.socialAccountsTTL,
      );

      // Inform the listeners about the new social accounts information.
      angular.forEach(this.listeners, (callback) => callback(this.entities));
    } else {
      this._getSocialAccountsWithStatus();
    }
  }
  /**
   * Inform of any social accounts change in the provided callback function.
   *
   * @param {string}   name      The name to register the listener.
   * @param {Function} callback  The callback function to inform the changes.
   */
  onChange(name, callback) {
    this.listeners[name] = callback;

    const storedInfo = this._getStorageInformation();
    if (storedInfo) {
      callback(storedInfo.list);
    }
  }
  /**
   * Clear the entities list and social mentions accounts.
   * Then, start the social accounts loading process again.
   */
  refreshSocialAccounts() {
    this.entities = [];
    this.socialMentionsAccounts = [];
    this.facebookPages = [];
    this._getSocialAccountsWithStatus();
  }
  /**
   * Sets the customer Id for the storage.
   *
   * @param {number} customerId  The new customer Id.
   */
  setCustomerId(customerId) {
    this.customerId = customerId;
  }
  /**
   * Check if any social mentions account is in error state.
   *
   * @access protected
   */
  _checkErrorState() {
    this.errorState.accounts = (
      !this.socialMentionsAccounts ||
      !this.socialMentionsAccounts.length ||
      this.socialMentionsAccounts.some((account) => (
        this.functionalitiesToCheck.some((functionality) => (
          !account.functionalities[functionality] ||
          !account.functionalities[functionality].enabled
        ))
      ))
    );
  }
  /**
   * Map the statuses of the functionalities for the specified child accounts list.
   *
   * @param {Array} accounts  The accounts list to format.
   *
   * @returns {Array}
   *
   * @access protected
   */
  _formatFunctionalitiesStatusForChildAccounts(accounts) {
    const { account: currentAccount } = this.appSession.getSession();

    return accounts ?
      accounts.map((childAccount) => {
        const functionalitiesStatuses = [
          {
            text: childAccount.functionalities.mention_collection.enabled ?
              'Collect by media tag' :
              'Cannot collect by media tag',
            active: childAccount.functionalities.mention_collection.enabled,
            reasonsForInactive: childAccount.functionalities.mention_collection.reasons_why_it_is_disabled,
          },
          {
            text: childAccount.functionalities.mention_collection.enabled ?
              'Collect by mention' :
              'Cannot collect by mention',
            active: childAccount.functionalities.mention_collection.enabled,
            reasonsForInactive: childAccount.functionalities.mention_collection.reasons_why_it_is_disabled,
          },
          {
            text: childAccount.functionalities.media_tag_collection.enabled ?
              'Collect from Instagram Business profiles' :
              'Cannot collect from Instagram Business profiles',
            active: childAccount.functionalities.media_tag_collection.enabled,
            reasonsForInactive: childAccount.functionalities.media_tag_collection.reasons_why_it_is_disabled,
          },
          {
            text: childAccount.functionalities.rights_request.enabled ?
              'Request rights programmatically' :
              'Cannot request rights programmatically',
            active: childAccount.functionalities.rights_request.enabled,
            reasonsForInactive: childAccount.functionalities.rights_request.reasons_why_it_is_disabled,
          },
        ];

        if (currentAccount.settings.activation_facebook_instagram) {
          functionalitiesStatuses.push({
            text: childAccount.functionalities.media_publishing_instagram.enabled ?
              'Publish media to Instagram Business profiles' :
              'Cannot publish media to Instagram Business profiles',
            active: childAccount.functionalities.media_publishing_instagram.enabled,
            reasonsForInactive: childAccount.functionalities.media_publishing_instagram.reasons_why_it_is_disabled,
          });
        }

        if (currentAccount.settings.activation_olapic_shoppable_instagram) {
          const reasonsForInactive = [];

          if (!childAccount.functionalities.media_publishing_tapshop.enabled) {
            reasonsForInactive.push('There is an unexpected error and we currently cannot publish media to Tapshop. ' +
              'Please contact your account manager.');
          }

          functionalitiesStatuses.push({
            text: childAccount.functionalities.media_publishing_tapshop.enabled ?
              'Publish media to TapShop' :
              'Cannot publish media to TapShop',
            active: childAccount.functionalities.media_publishing_tapshop.enabled,
            reasonsForInactive,
            hideSolution: true,
          });
        }

        const allActive = !functionalitiesStatuses.some((functionality) => !functionality.active);

        return {
          ...childAccount,
          functionalitiesStatuses,
          allActive,
        };
      }) :
      [];
  }
  /**
   * Format an API response in order to the get the social accounts list.
   *
   * @param {Object} response  The response to format.
   *
   * @returns {Array}
   *
   * @access protected
   */
  _formatResponse(response) {
    this.socialMentionsAccounts = this._formatFunctionalitiesStatusForChildAccounts(response.child_accounts);
    this.facebookPages = response.facebook_pages;
    this._checkErrorState();

    return [response];
  }
  /**
   * Cancel the current $timeout and request in progress.
   * Then, call the API to make the social accounts with status request.
   *
   * @access protected
   */
  _getSocialAccountsWithStatus() {
    this.$timeout.cancel(this._currentTimeout);
    this.cancelCurrentRequest()
    .then(() => this._getEntities())
    .then(() => {
      this.errorState.loginFailed = false;
      // Save the social accounts list and ttl.
      const storedInformation = this.$localStorage[this.sessionKey] || {};
      this.$localStorage[this.sessionKey] = {
        ...storedInformation || {},
        [this.customerId]: {
          list: this.entities,
          ttl: new Date().getTime() + this.socialAccountsTTL,
        },
      };

      // Refresh the social acccounts in the given social accounts TTL.
      this._currentTimeout = this.$timeout(
        () => this._getSocialAccountsWithStatus(),
        this.socialAccountsTTL,
      );
    })
    .catch((error) => {
      if (
        error &&
        error.status &&
        error.status === statuses['precondition failed']
      ) {
        this.errorState.loginFailed = true;
      }
      this.appErrorHandler.silent(error);
    })
    .finally(() => {
      // Inform the listeners about the new social accounts information.
      angular.forEach(this.listeners, (callback) => callback(this.entities));
    });
  }
  /**
   * Get the stored information provided that there is any and if it has not expired yet.
   *
   * @returns {Object}
   *
   * @access protected
   */
  _getStorageInformation() {
    const storedInformation = this.$localStorage[this.sessionKey] || {};
    const { list, ttl } = storedInformation[this.customerId] || {};
    let timeToExpire = 0;

    if (ttl) {
      timeToExpire = this.moment(ttl).diff(this.moment());
    }

    return list && timeToExpire >= 0 ?
      { list, timeToExpire } :
      null;
  }
  /**
   * Call the API to make the request for the social accounts with status list.
   *
   * @returns {Promise}
   *
   * @access protected
   */
  _makeFirstRequest() {
    this._currentRequest = this.appAPI.getSocialAccountsWithStatus();

    return this._currentRequest;
  }
}

export default SocialAccountsWithStatusList;
