/* eslint-disable max-len */
/**
 * @ngdoc constant
 * @name SETTINGS_INSTRUCTIONS
 * @description
 * The text for Checkout Pixel implementation instructions.
 *
 * @type {string}
 *
 * @memberof settings
 */
const settingsInstructions = `
  Insert the code code in your checkout confirmation page to track the improvement in conversions generated by Olapic. You can pass information about all the products. Please refer to the following variables we need:
  <br/><br/>
  <strong>Purchased Product's ID</strong>: The unique product ID from your store. This product ID MUST match the product ID being used from the product feed.
  <br/>
  Refer to PRODUCT_ID in the code above.
  <br/>
  <strong>Product price</strong>: The price of each purchased product including sales / discounts.
  <br/>
  Refer to PRODUCT_PRICE in the code above.
  <br/>
  <strong>Transaction ID</strong>: The unique transaction ID from your from your store.
  <br/>
  Refer to TRANSACTION_ID in the code above.
  <br/>
  <strong>Currency</strong>: The ISO 4217 Alphabetic code of the currency. e.g. Use 'EUR' if the Amount value is in Euro. This field is optional and the default value is 'USD'
  <br/>
  Refer to CURRENCY in the code above.
  <br/>
  <strong>Segmentation</strong>: Key and value pairs will be used to group and display the results, you can send ad many pairs as needed, only one value for each key is allowed.
  <br/>
  Refer to SEGMENT_KEY and SEGMENT_VALUE in the code above.
  <br/><br/>
  Please pay close attention to the "product loop" portion of the code. You will need to loop through the each product that is in the cart with the shorthand operator to append objects for each product.
`;
export default settingsInstructions;
