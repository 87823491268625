import LIBRARY_MEDIA_STATUS from './mediaStatus.constant';

/**
 * @ngdoc constant
 * @name LIBRARY_SECTIONS
 * @description
 * The list of sections for the library.
 *
 * @type {Array}
 * @property {string} id              The section id.
 * @property {string} name            The section name.
 * @property {string} permissionName  The permissions name to check when asserting if the section should be
 *                                    visible or not.
 * @property {string} defaultSort     The default sorting option.
 * @property {Object} filters         The {@link QueryFilter} to set to the query when the section is selected.
 * @property {Object} tooltip         The section tooltip information.
 *
 * @memberof library
 */
const librarySections = [{
  id: 'new',
  name: 'New Content',
  permissionName: 'content.tab.content',
  defaultSort: 'dateCollectedDesc',
  filters: {
    status_id: {
      values: [LIBRARY_MEDIA_STATUS.pending],
    },
    actionable_rights_status: { values: true },
  },
  helpText: 'Media collected from your collectors available to Approve, <br/>' +
  ' Discard or Request Rights to be used on your site',
  emptyMessage: 'You can <a href="/collections">create a collection</a> or ' +
    '<a href="/content-uploader">upload media</a> to start approving your content.',
}, {
  id: 'approved',
  name: 'Approved Content',
  permissionName: 'content.tab.approved',
  defaultSort: 'approvedDesc',
  filters: {
    status_id: {
      values: [LIBRARY_MEDIA_STATUS.approved],
    },
  },
  helpText: 'All media collected with rights approved. <br/> ' +
  ' Hover over the content to see sharing options.',
  emptyMessage: 'When you get the rights to your approved content it will appear here.' +
    ' Go to <a href ng-click="$ctrl.onSelectedSectionChange({ id: \'new\' })">New Content</a> to start approving.',
}, {
  id: 'savedForLater',
  name: 'Saved For Later',
  permissionName: 'content.tab.savedforlater',
  defaultSort: 'dateCollectedDesc',
  filters: {
    status_id: {
      values: [LIBRARY_MEDIA_STATUS.savedForLater],
    },
    actionable_rights_status: { values: true },
  },
  emptyMessage: 'If you\'re not sure about something or you need someone else to review it,' +
    ' you can save it for later and it will be moved here.',
}, {
  id: 'rightsPending',
  name: 'Rights Pending',
  permissionName: 'content.tab.rightspending',
  defaultSort: 'dateCollectedDesc',
  filters: {
    status_id: {
      values: [LIBRARY_MEDIA_STATUS.pending, LIBRARY_MEDIA_STATUS.savedForLater],
    },
    actionable_rights_status: { values: false },
  },
  emptyMessage: 'All content that is waiting for a rights response will live here.',
}, {
  id: 'reported',
  name: 'Reported',
  permissionName: 'content.tab.reported',
  defaultSort: 'dateCollectedDesc',
  filters: {
    status_id: {
      values: [LIBRARY_MEDIA_STATUS.reported],
    },
  },
  emptyMessage: 'If someone reports content in a widget or lightbox it will appear here.' +
    ' If you think it was misreported you can Re-Approve.',
}, {
  id: 'discarded',
  name: 'Discarded',
  permissionName: 'content.tab.discarded',
  defaultSort: 'discardedDesc',
  filters: {
    discarded: {
      values: true,
    },
    media_predeleted_only: {
      values: true,
    },
  },
  emptyMessage: 'When you discard content you can always find it here and Restore it if you change your mind.',
}];

export default librarySections;
