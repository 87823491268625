import angular from 'angular';

import blockedList from './components/blockedList/blockedList.component';

import blockedListContainer from './containers/blockedList.container';

import BlockedHashtagsList from './services/blockedHashtagsList';
import BlockedUsersList from './services/blockedUsersList';

/**
 * @ngdoc overview
 * @name blockedList
 * @description
 * This is the application blockedList module, it displays the blocked list section.
 */
const blockedListModule = angular.module('blockedList', [])
.component('blockedList', blockedList)
.component('blockedListContainer', blockedListContainer)
.factory('blockedHashtagsList', BlockedHashtagsList)
.factory('blockedUsersList', BlockedUsersList);
export default blockedListModule.name;
