import template from './whitelistForm.html';
import './whitelistForm.scss';

/**
 * @ngdoc component
 * @name WhitelistForm
 * @description
 * This component renders the whitlisted users form.
 *
 * @memberof whitelist
 */
class WhitelistForm {
  constructor() {
    /**
     * The dates range for whitelist users.
     *
     * @type {Object}
     */
    this.dates = {};
    /**
     * The whitelist usernames.
     *
     * @type {string}
     */
    this.usernames = '';
    /**
     * The username input's error message.
     *
     * @type {string}
     */
    this.usernamesError = '';
    /**
     * The selected source.
     *
     * @type {?Object}
     */
    this.selectedSource = 'instagram';
    /**
     * The whitelist user labels.
     *
     * @type {string}
     */
    this.labels = '';
    /**
     * Amount of label suggestions to show.
     *
     * @type {number}
     */
    this.suggestionsLimit = 5;
    /**
     * Flag to know if a request to save the whitelist users is in progress.
     *
     * @type {boolean}
     */
    this.isSaving = false;
    /**
     * Array that keeps the already parsed/sanitized usernames.
     *
     * @type {Array<string>}
     */
    this.parsedUsernames = [];
    /**
     * The number of users to edit.
     *
     * @type {number}
     */
    this.usersCount = 0;
    /**
     * The map of the availables error messages when validating the usernames.
     *
     * @type {Object}
     * @property {string} invalid    The error message to display when the usernames are invalid.
     * @property {string} maxAmount  The error message to display when the usernames are more than the limit.
     * @access protected
     */
    this._usernamesErrorMessages = {
      invalid: 'Please make sure the usernames only include letters, numbers, periods, and underscores.',
      maxAmount: 'You can\'t add more than 200 users at once.',
    };
    /**
     * The max amount of usernames that the user is able to create.
     *
     * @type {number}
     * @access protected
     */
    this._usernamesLimit = 200;
  }
  /**
   * Set the initial load for the form fields.
   */
  $onInit() {
    if (this.userToEdit) {
      this.usernames = this.userToEdit.username;
      this.selectedSource = this.userToEdit.source;
      this.labels = this.labels ? this.labels : this.userToEdit.labels;
      this.dates = {
        dateFrom: this.userToEdit.dateFrom ? new Date(this.userToEdit.dateFrom) : null,
        dateTo: this.userToEdit.dateTo ? new Date(this.userToEdit.dateTo) : null,
      };
    }

    if (this.usersToEdit) {
      this.usersCount = Object.keys(this.usersToEdit).length;
      this.labels = this.labels || this.usersLabels;
    }
  }
  /**
   * Clear form after submit.
   *
   * @param {Object} changes          The binding changes.
   * @param {Object} changes.loading  The loading change object.
   */
  $onChanges({ loading }) {
    if (
      loading &&
      loading.previousValue &&
      !loading.currentValue &&
      this.wasTheSubmitSuccessful &&
      this.isSaving
    ) {
      this.isSaving = false;
      this._clearForm();
    }
  }
  /**
   * Set the corresponding date related to the form data.
   *
   * @param {string} date   The type of date.
   * @param {Date}   value  The date value.
   */
  onDateSet(date, value) {
    this.dates[date] = value;
  }
  /**
   * Callback triggered when the label input changes.
   *
   * @param {string} labels  The labels to set.
   */
  onLabelsSet(labels = '') {
    this.labels = labels;
    this.onSearchLabelSuggestions({
      search: this.labels,
      limit: this.suggestionsLimit,
    });
  }
  /**
   * Call the onSaveWhitelistUsers callback with the whitelist users and the selected source.
   */
  onSubmit() {
    this.isSaving = true;
    const { dateFrom, dateTo } = this.dates;
    const isEditing = !!this.userToEdit || !!this.usersToEdit;
    let labelsToSubmit = this.labels;
    if (!angular.isArray(labelsToSubmit)) {
      labelsToSubmit = [this.labels];
    }

    let whitelistUsers = {};

    const whitelistUsersBaseData = {
      labels: this.labels.length ? labelsToSubmit : [],
      dateFrom: dateFrom ? dateFrom.toISOString() : null,
      dateTo: dateTo ? dateTo.toISOString() : null,
    };

    if (this.usersCount > 1) {
      whitelistUsers = {
        ...whitelistUsersBaseData,
        whitelistUsers: this.usersToEdit,
      };
    } else {
      whitelistUsers = {
        ...whitelistUsersBaseData,
        whitelistUsers: this._sanitizeUsernames(this.usernames),
        source: this.selectedSource,
      };
    }

    if (isEditing) {
      this.onSaveUserEdit({ user: whitelistUsers });
    } else {
      this.onSaveWhitelistUsers(whitelistUsers);
    }
  }
  /**
   * Called when there is a change in the whitelist form's input
   * or after modal submit.
   */
  onUsernameInputChange() {
    const usernames = this.usernames || '';
    this.parsedUsernames = [];
    this.usernamesError = '';

    if (this._validateInput()) {
      this.usernames = usernames.replace(/(\n| \n)/g, ' ');
      this.parsedUsernames = this._sanitizeUsernames(this.usernames);

      if (this.parsedUsernames.length > this._usernamesLimit) {
        this.usernamesError = this._usernamesErrorMessages.maxAmount;
      }
    } else {
      this.usernamesError = this._usernamesErrorMessages.invalid;
    }
  }
  /**
   * It clears form's data.
   */
  _clearForm() {
    this.dates = {};
    this.labels = '';
    this.parsedUsernames = [];
    this.usernames = '';
    this.onSearchLabelSuggestions({ search: '' });
  }
  /**
   * Parse a list of usernames in order to fulfill with a username spec.
   *
   * @param {string} usernames  Contains the string with usernames.
   *
   * @returns {Array}
   */
  _sanitizeUsernames(usernames) {
    return usernames
    // Remove any comma, with and without spaces around them.
    .replace(/(?: )?,(?: )?/g, ' ')
    // Remove mentions and hashtags chars.
    .replace(/(@|#)/g, '')
    // Split the list using spaces.
    .split(' ')
    // Remove any leading and/or trailing spaces from the names.
    .map((username) => username.trim())
    // Remove empty strings.
    .filter((username) => username.length)
    // Create the whitelist user objects.
    .map((username) => ({ username }));
  }
  /**
   * Validate the user's input in order to set the error message value
   * in case the input is invalid. It Checks:
   * - Only allowed characters (letters, numbers, period and underscore, hash/mentions, comma).
   * - No repetition for mention/hash/comma.
   * - No mention/hash allowed at the end of an username.
   *
   * @returns {boolean}
   *
   * @access protected
   */
  _validateInput() {
    const allowedCharsRegExp = new RegExp('^[@#\\w.\\s,]*$');
    const repeteadCharsRegExp = new RegExp('([@#,](@|#|,)\\1)');
    const invalidCharPositionRegExp = new RegExp('([\\w|.][@|#])');

    return allowedCharsRegExp.test(this.usernames) &&
      !repeteadCharsRegExp.test(this.usernames) &&
      !invalidCharPositionRegExp.test(this.usernames);
  }
}

/**
 * @ngdoc component
 * @name whitelistForm
 * @description
 * The whitelist form component.
 *
 * @memberof whitelist
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {WhitelistForm}
   */
  controller: WhitelistForm,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {Array}    labelSuggestions          The list of label suggestions to display.
   * @property {boolean}  loading                   If a request for the whitelist is in progress.
   * @property {Array}    usersLabels               The labels to prefill the labels input.
   * @property {Object}   userToEdit                The user to be edited, if any.
   * @property {Object}   usersToEdit               The users to be edited in bulk, if any.
   * @property {boolean}  wasTheSubmitSuccessful    Flag to indicate the result of the form's submit request.
   * @property {Function} onCancel                  Callback when closing and canceling any edition to the modal.
   * @property {Function} onSaveUserEdit            Callback for when we want to save changes to a user in the list.
   * @property {Function} onSaveWhitelistUsers      Callback for when we want to add a user to the list.
   * @property {Function} onSearchLabelSuggestions  Callback for when we want to search label suggestions.
   */
  bindings: {
    labelSuggestions: '<',
    loading: '<',
    usersLabels: '<',
    userToEdit: '<',
    usersToEdit: '<',
    wasTheSubmitSuccessful: '<',
    onCancel: '&',
    onSaveUserEdit: '&',
    onSaveWhitelistUsers: '&',
    onSearchLabelSuggestions: '&',
  },
};
