import template from './root.html';
import './root.scss';

/**
 * @ngdoc component
 * @name root
 * @description
 * The main wrapper component of the app. It transcludes the router view.
 *
 * @memberof rootModule
 */
export default {
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {string}   appTitle         The app title.
   * @property {string}   avatar           The avatar url.
   * @property {string}   currentRoute     The current route.
   * @property {string}   customer         The customer name.
   * @property {string}   email            The user email.
   * @property {boolean}  hideMenu         If we must hide the menu.
   * @property {boolean}  loading          If we must display the loading indicator or not.
   * @property {Object}   menu             The data to show on the menu.
   * @property {Object}   showAvatarError  If we have to show the error dot in the avatar.
   * @property {Function} onFilter         Callback to filter the menu items.
   */
  bindings: {
    appTitle: '@',
    avatar: '@',
    currentRoute: '@',
    customer: '@',
    email: '@',
    hideMenu: '<',
    loading: '<',
    menu: '<',
    showAvatarError: '<',
    onFilter: '&',
  },
};
