import template from './settings.html';
import './settings.scss';

/**
 * @ngdoc controller
 * @name Settings
 * @description
 * This component renders the settings section.
 *
 * @memberof settings
 */
class Settings {
  /**
   * @param {$location} $location   To get the current hash.
   * @param {$window}   $window     To add the hashchange listener.
   * @param {AppEvents} appEvents   To emit the app events.
   * @param {Object}    APP_EVENTS  To emit an hideAvatarError event.
   */
  constructor($location, $window, appEvents, APP_EVENTS) {
    'ngInject';

    /**
     * The local reference to the `$location` service.
     *
     * @type {$location}
     */
    this.$location = $location;
    /**
     * The local reference to the `$window` service.
     *
     * @type {$window}
     */
    this.$window = $window;
    /**
     * The local reference to the `appEvents` service.
     *
     * @type {AppEvents}
     */
    this.appEvents = appEvents;
    /**
     * The local reference to the `APP_EVENTS` constant.
     *
     * @type {Object}
     */
    this.APP_EVENTS = APP_EVENTS;
    /**
    /**
     * The section of the settings page that will be displayed.
     *
     * @type {?string}
     */
    this.currentSection = null;
    /**
     * The map of posible sections.
     *
     * @type {Array}
     */
    this.sections = {
      account: 'account',
      socialAccounts: 'socialAccounts',
    };
    /**
     * Flag to know if we have to show the error dot in the social accounts option.
     *
     * @type {boolean}
     */
    this.showSocialAccountsMenuError = false;
    /**
     * @ignore
     */
    this.onHashChange = this.onHashChange.bind(this);
  }
  /**
   * Trigger the first onHashChange and add the hashchange listener.
   */
  $onInit() {
    this.onHashChange();

    this.$window.addEventListener('hashchange', this.onHashChange, false);
  }
  /**
   * Each time the socialAccountsErrorState binding changes, update the showSocialAccountsMenuError variable.
   *
   * @param {Object} changes                           The binding changes.
   * @param {Object} changes.socialAccountsErrorState  The socialAccountsErrorState change object.
   */
  $onChanges({ socialAccountsErrorState }) {
    if (
      socialAccountsErrorState &&
      socialAccountsErrorState.currentValue
    ) {
      /**
       * If the current section is the socialAccounts, set the showSocialAccountsMenuError to false.
       * If not, take the value from the socialAccountsErrorState variable.
       */
      if (this.currentSection === 'socialAccounts') {
        this.showSocialAccountsMenuError = false;
      } else {
        this.showSocialAccountsMenuError = this.socialAccountsErrorState.accounts ||
          this.socialAccountsErrorState.loginFailed;
      }
    }
  }
  /**
   * Remove the hashchange listener on destroy.
   */
  $onDestroy() {
    this.$window.removeEventListener('hashchange', this.onHashChange, false);
  }
  /**
   * Change the section when a hashchange is detected.
   */
  onHashChange() {
    const section = this.sections[this.$location.hash()];

    if (section) {
      this.currentSection = section;
      /**
       * If the user is in the social accounts section of the settings page,
       * hide the social accounts menu error and emit a hide avatar error menu event.
       */
      if (this.currentSection === 'socialAccounts') {
        this.showSocialAccountsMenuError = false;
        this.appEvents.emit(this.APP_EVENTS.menu.hideAvatarError);
      }
    } else {
      // If no correct section was provided we need to set the default one.
      this.$location.replace().hash(this.sections.account);
    }
  }
  /**
   * Changes the current section.
   *
   * @param {string} section  The section that will be selected.
   */
  onSectionChange(section) {
    this.$location.hash(section);
  }
}

/**
 * @ngdoc component
 * @name settings
 * @description
 * This component renders the settings section.
 *
 * @memberof settings
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {Settings}
   */
  controller: Settings,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {string}   accountApiKey              The customer's api key.
   * @property {string}   accountAvatar              The customer's account avatar.
   * @property {string}   accountName                The customer's account name.
   * @property {string}   authUrl                    The url for social account authentication.
   * @property {string}   checkoutPixel              The checkout pixel snippet code.
   * @property {string}   checkoutPixelInstructions  The checkout pixel instructions.
   * @property {Array}    currencies                 The list of currencies to display.
   * @property {Object}   facebookPages              The facebook pages associated with this customer.
   * @property {Object}   settings                   The account's settings.
   * @property {Object}   socialAccounts             The social accounts associated with this customer.
   * @property {Object}   socialAccountsErrorState   Map of the social accounts error state.
   * @property {boolean}  socialAccountsLoading      If there are social accounts being loaded.
   * @property {Object}   socialMentionsAccounts     The social mentions accounts associated with this customer.
   * @property {Function} onRemoveSocialAccount      Callback to delete a social account from customer.
   * @property {Function} onSaveAvatar               Callback to save the account's avatar.
   * @property {Function} onSaveSettings             Callback to save the account's settings.
   * @property {Function} onSaveSocialAccount        Callback to get the social accounts list when there is a new one.
   */
  bindings: {
    accountApiKey: '<',
    accountAvatar: '<',
    accountName: '<',
    authUrl: '<',
    checkoutPixel: '<',
    checkoutPixelInstructions: '<',
    currencies: '<',
    facebookPages: '<',
    settings: '<',
    socialAccounts: '<',
    socialAccountsErrorState: '<',
    socialAccountsLoading: '<',
    socialMentionsAccounts: '<',
    onRemoveSocialAccount: '&',
    onSaveAvatar: '&',
    onSaveSettings: '&',
    onSaveSocialAccount: '&',
  },
};
