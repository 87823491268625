/**
 * @ngdoc constant
 * @name APP_EVENTS
 * @description
 * Object of app events.
 *
 * @type {Object}
 * @property {Object} session               The session app events.
 * @property {string} session.unauthorized  The unauthorized session event.
 * @property {Object} menu                  The menu app events.
 * @property {string} menu.hideAvatarError  The hide avatar error menu event.
 *
 * @memberof rootModule
 */
const appEvents = {
  session: {
    unauthorized: 'appEvents:sessionUnauthorized',
  },
  menu: {
    hideAvatarError: 'appEvents:menuHideAvatarError',
  },
};

export default appEvents;
