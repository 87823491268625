/**
 * @ngdoc constant
 * @name EXTERNAL_APPS_PATHS
 * @description
 * Object of external apps paths.
 *
 * @type {Object}
 * @property {string} contentPublishing  The content publishing app path.
 * @property {string} globalDashboard    The global dashboard app path.
 * @property {string} lemuramaAnalytics  The lemurama analytics app path.
 * @property {string} ncd                The NoCodeDesigner app path.
 * @property {string} photorank          The photorank app path.
 * @property {string} sandbox            The sandbox app path.
 * @property {string} tapshopAdmin       The tapshopAdmin app path.
 *
 * @memberof externalApp
 */
const externalAppsPaths = {
  contentPublishing: '/autologin/:customerId/:token/:userEmail/:route',
  globalDashboard: '/earned/login',
  lemuramaAnalytics: 'autologin/:customerId/:token/:route',
  ncd: '/',
  photorank: '/:route',
  sandbox: '/:route',
  tapshopAdmin: '/',
};

export default externalAppsPaths;
